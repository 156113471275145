import {defaultStyle} from "./modules/custom-styles";

const Lodash = require('lodash');

const CustomStyles = require('./modules/custom-styles');
const TemplateEditor = require('./modules/template-editor');
const Sidebar = require('./modules/sidebar');
import { Toast, Modal } from 'bootstrap';

/**
 * Fetch template to use and init editor
 */

let editor, editorTempData, toastError, templateVariablesEditMode, templateEventType;

function onEditorReady(themeData) {
  initTestButton();
  initToolbarMenu();
  initSidebarEvents(themeData);
  initAddBlocksMenu();
  initActionsMenu();

  startEditing();
  initIntro();
}
/****
 * End: Adding mock data
 */


export function initEditor({
                            templateData,
                            team_id,
                            template_id,
                            template_event_type,
                            authenticity_token,
                            company_settings,
                            template_settings,
                            json_stripe_intent,
                            json_stripe_customer,
                            json_stripe_invoice,
                            json_stripe_charge,
                            json_stripe_checkout_session,
                            payment_object,
                            themeData,
                          }) {
  //Create object holding data for template variables
  templateEventType = template_event_type;
  console.log("template_event_type", templateEventType);

  templateVariablesEditMode = {"stripe": {}};
  templateVariablesEditMode.stripe["intent"] = json_stripe_intent;
  templateVariablesEditMode.stripe["customer"] = json_stripe_customer;
  templateVariablesEditMode.stripe["invoice"] = json_stripe_invoice;
  templateVariablesEditMode.stripe["charge"] = json_stripe_charge;
  templateVariablesEditMode.stripe["checkout_session"] = json_stripe_checkout_session;
  templateVariablesEditMode["payment"] = payment_object;
  templateVariablesEditMode["settings"] = company_settings;
  console.log("templateVariablesEditMode", templateVariablesEditMode);
  console.log("template_settings", template_settings);
  templateVariablesEditMode.settings["template"] = template_settings;

  Sidebar.init(templateVariablesEditMode, themeData);
  console.log('templateData', templateData)
  editorTempData = templateData;
  editor = TemplateEditor.init("edit", templateData, templateVariablesEditMode, authenticity_token, () => {
    onEditorReady(themeData);
    initSaveButton();
  });


  /**
   * Buttons
   */
  const editButton = document.getElementById('editButton');
  const saveButton = document.getElementById('saveButton');

  /**
   * Saving
   */
  const saveFunction = (button) => {
    editor.save().then((editorData) => {
      //Clean editor data
      TemplateEditor.cleanEditorData(editorData);

      //Temp save of editor data
      editorTempData = Lodash.cloneDeep(editorData);

      //Test template content before save
      TemplateEditor.preview(editorData, templateVariablesEditMode);

      if(button){
        $(button).html('Saving...');
      }

      // Create url
      let url = `/account/teams/${team_id}/templates`;
      let method = 'POST';
      if (template_id && template_id !== "-1") {
        // Update url
        url = `/account/templates/${template_id}`;
        method = 'PUT';
      }
      //Send to server
      fetch(url, {
        method: method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authenticity_token: authenticity_token,
          template: {
            body: editorTempData,
            settings: templateVariablesEditMode.settings["template"]
          }
        }),
      })
        .then(response => response.json())
        .then(response => {
          if(button){
            $(button).html('Saved!');
          }
          setTimeout(() => {
            if(button){
              $(button).html('Save changes');
            }
            Sidebar.inputsDisabled(false);
          }, 1000)
          if (method === 'POST') {
            window.location.href = response.template_url;
          }
          editorTempData = response.body;
        });

      //Render cleaned data in editor
      editor.render(editorTempData).then(() => {
        editorTempData = {};
      });

      resetErrorMessage();
    })
    .catch((error) => {
      console.error(error);
      showErrorMessage("Click <i>Test template</i> button to see more info.");
    });
  };
  const initSaveButton = function () {
    $('#btn-save-changes').parent().popover({
      content: 'Close test before saving',
      trigger: 'hover',
      placement: 'bottom',
      template: '<div class="popover popover-dark" role="tooltip"><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    }).popover('disable');

    $('#btn-save-changes').on('click', function (e) {
      saveFunction(this);
    });
  };

  return [editor, saveFunction];
}


/**
 * Buttons and menus
 */

const initIntro = function () {
  let isChrome = (navigator.userAgent.indexOf("Chrome") != -1 && navigator.vendor.indexOf("Google Inc") != -1)
  
  let browserModal = new Modal('#browserModal', {focus: true} );
  let introModal = new Modal('#introModal', {focus: true} );

  const _showIntroModal = function (introModal) {
    // Let's show the modal unless users has saved preference to not show it
    if(localStorage.getItem('doNotShowIntro') !== 'true'){
      introModal.show();
    } else {
      if(isEditorEmpty()) {
        onMenuClick('blocks');
      }
    }
  }

  //Show warning if browser is not Chrome
  if (!isChrome) {
    browserModal.show();
  } else {
    _showIntroModal(introModal);
  }

  //When browser modal is closed
  $('#browserModal').on('hidden.bs.modal', function (e) {
    _showIntroModal(introModal);
  });

  //When intro modal is closed
  $('#introModal').on('hidden.bs.modal', function (e) {
    if($('#doNotShowIntro').is(':checked')){
      localStorage.setItem('doNotShowIntro', 'true');
    }
    if(isEditorEmpty()) {
      onMenuClick('blocks');
    }
  });
}

const initToolbarMenu = function () {
  $('#menu-item-design a').on('click', function (e) {
    onMenuClick('design');
  });
  $('#menu-item-variables a').on('click', function (e) {
    onMenuClick('variables');
  });

  $('#menu-item-blocks a').on('click', function (e) {
    onMenuClick('blocks');
  });

  $('#menu-item-actions a').on('click', function (e) {
    onMenuClick('actions');
  });
};

const initAddBlocksMenu = function () {
  let category;
  let whatDataObj = getStripeDataObjectOfInterest();

  // Receipt blocks
  category = $('#blocks-receipt');
  addBlockButton(category, 'Headers', 'Header').on('click', function (e) {
    insertBlock('header', {"text": "Receipt","level": 1});
  });
  addBlockButton(category, 'Details', 'Date').on('click', function (e) {
    insertBlock('paragraph', {"text":"Date: {{showDate payment.created}}"});
  });
  addBlockButton(category, 'Details', 'Company details').on('click', function (e) {
    insertBlock('paragraph', {"text":"{{settings.company.name}}<br>{{fullAddress settings.company}}"});
  });
  addBlockButton(category, 'Details', 'Customer details').on('click', function (e) {
    insertBlock('paragraph', {"text":""});
    insertBlock('table', {"withHeadings":true,"content":[["Billing details"],["{{payment.customer.name}}<br>{{fullAddress payment.customer}}"]]}, {"tableborderTune":"show"});
  });
  switch (whatDataObj) {
    case 'invoice':
      addBlockButton(category, 'Details', 'Line items').on('click', function (e) {
        insertBlock('paragraph', {"text":""});
        insertBlock('table', {"withHeadings":true,"loopRow":{"row":2,"array":"stripe.invoice.lines.data"},"content":[["Description","Quantity","Price per unit","Amount excl tax","Amount incl tax"],["{{description}}","{{quantity}}","{{showWithCurrency \"price.unit_amount\"}}","{{showWithCurrency \"amount_excluding_tax\"}}","{{showWithCurrency \"amount\"}}"],["","","<b>Total</b>","<b>{{showWithCurrency \"payment.amount.total_excluding_tax\"}}</b><br>","<b>{{showWithCurrency \"payment.amount.total\"}}</b>"]]}, {"tableborderTune":"show_center"});
      });
      break;
    case 'checkout_session':
      addBlockButton(category, 'Details', 'Line items').on('click', function (e) {
        insertBlock('paragraph', {"text":""});
        insertBlock('table', {"withHeadings":true,"loopRow":{"row":2,"array":"stripe.checkout_session.line_items.data"},"content":[["Description","Quantity","Price per unit","Tax","Sub total"],["{{description}}","{{quantity}}","{{showWithCurrency \"price.unit_amount\"}}","{{showWithCurrency \"amount_tax\"}}","{{showWithCurrency \"amount_subtotal\"}}"]]}, {"tableborderTune":"show_center"});
      });
      addBlockButton(category, 'Details', 'Total amounts').on('click', function (e) {
        insertBlock('table', {"withHeadings":false,"content":[["","","","Tax total","{{showWithCurrency \"payment.amount.total_tax\"}}"],["","","","Shipping","{{showWithCurrency \"payment.amount.total_shipping\"}}"],["","","","<b>Total</b>","<b>{{showWithCurrency \"payment.amount.total\"}}</b>"]]}, {"tableborderTune":"show_top"});
      });
      break;
    case 'intent':
      addBlockButton(category, 'Details', 'Purchase summary').on('click', function (e) {
        insertBlock('paragraph', {"text":""});
        insertBlock('table', {"withHeadings":true,"content":[["Description","Amount"],["{{payment.description}}","{{showWithCurrency \"payment.amount.total\"}}"]]});
      });
      addBlockButton(category, 'Details', 'Payment status').on('click', function (e) {
        insertBlock('paragraph', {"text":"Payment status: {{stripe.intent.status}}"});
      });
      break;
    case 'charge':
      break;
    case 'customer':
      break;
    default:
  }
  addBlockButton(category, 'Footers', 'Footer').on('click', function (e) {
    insertBlock('paragraph', {"text":""});
    insertBlock('delimiter', {}, {"delimitermodeTune":"bottom"});
    insertBlock('paragraph', {"text":"{{settings.company.name}}<br>{{settings.company.email}}"}, {"alignmentTune":"center"});
  });

  // Packing slip blocks
  category = $('#blocks-packingslip');
  addBlockButton(category, 'Headers', 'Header').on('click', function (e) {
    insertBlock('header', {"text": "Packing slip","level": 1});
  });
  addBlockButton(category, 'Details', 'Date').on('click', function (e) {
    insertBlock('paragraph', {"text":"Date: {{showDate payment.created}}"});
  });
  addBlockButton(category, 'Details', 'Company details').on('click', function (e) {
    insertBlock('paragraph', {"text":"{{settings.company.name}}<br>{{fullAddress settings.company}}"});
  });
  switch (whatDataObj) {
    case 'invoice':
      addBlockButton(category, 'Details', 'Customer details').on('click', function (e) {
        insertBlock('paragraph', {"text":""});
        insertBlock('paragraph', {"text":"<b>Customer:</b> {{payment.customer.name}}"});
        insertBlock('table', {"withHeadings":true,"content":[["Billing details","Shipping details"],["{{payment.customer.name}}<br>{{fullAddress payment.customer}}","{{payment.shipping_details.name}}<br>{{fullAddress payment.shipping_details}}"]]});
      });
      addBlockButton(category, 'Details', 'Line items').on('click', function (e) {
        insertBlock('paragraph', {"text":""});
        insertBlock('paragraph', {"text":"<b>Products</b>"});
        insertBlock('table', {"withHeadings":true,"loopRow":{"row":2,"array":"stripe.invoice.lines.data"},"content":[["Description","Quantity"],["{{description}}","{{quantity}}"]]});
      });
      break;
    case 'checkout_session':
      addBlockButton(category, 'Details', 'Customer details').on('click', function (e) {
        insertBlock('paragraph', {"text":""});
        insertBlock('paragraph', {"text":"<b>Customer:</b> {{payment.customer.name}}"});
        insertBlock('table', {"withHeadings":true,"content":[["Billing details","Shipping details"],["{{payment.customer.name}}<br>{{fullAddress payment.customer}}","{{payment.shipping_details.name}}<br>{{fullAddress payment.shipping_details}}"]]});
      });
      addBlockButton(category, 'Details', 'Line items').on('click', function (e) {
        insertBlock('paragraph', {"text":""});
        insertBlock('paragraph', {"text":"<b>Products</b>"});
        insertBlock('table', {"withHeadings":true,"loopRow":{"row":2,"array":"stripe.checkout_session.line_items.data"},"content":[["Description","Quantity"],["{{description}}","{{quantity}}"]]});
      });
      break;
    case 'intent':
      addBlockButton(category, 'Details', 'Customer details').on('click', function (e) {
        insertBlock('paragraph', {"text":""});
        insertBlock('paragraph', {"text":"<b>Customer:</b> {{payment.customer.name}}"});
        insertBlock('table', {"withHeadings":true,"content":[["Billing details","Shipping details"],["{{payment.customer.name}}<br>{{fullAddress payment.customer}}","{{payment.shipping_details.name}}<br>{{fullAddress payment.shipping_details}}"]]});
      });
      addBlockButton(category, 'Details', 'Product items').on('click', function (e) {
        insertBlock('paragraph', {"text":""});
        insertBlock('paragraph', {"text":"<b>Products</b>"});
        insertBlock('table', {"withHeadings":true,"content":[["Description"],["{{payment.description}}"]]});
      });
      break;
    case 'charge':
      break;
    case 'customer':
      break;
    default:
  }
  addBlockButton(category, 'Footers', 'Footer').on('click', function (e) {
    insertBlock('paragraph', {"text":""});
    insertBlock('delimiter', {}, {"delimitermodeTune":"bottom"});
    insertBlock('paragraph', {"text":"{{settings.company.name}}<br>{{settings.company.email}}"}, {"alignmentTune":"center"});
  });

}

const getStripeDataObjectOfInterest = function () {
  if (templateVariablesEditMode.stripe.invoice) {
    return 'invoice';
  }
  if (templateVariablesEditMode.stripe.checkout_session) {
    return 'checkout_session';
  }
  if (templateVariablesEditMode.stripe.intent) {
    return 'intent';
  }
  if (templateVariablesEditMode.stripe.charge) {
    return 'charge';
  }
  if (templateVariablesEditMode.stripe.customer) {
    return 'customer';
  }
  return 'none';
}

const addBlockButton = function (category, subcategory, buttontext) {
  let parent = getBlockParent(category, subcategory);
  
  let container = $('<div>');
  let button = $('<button>', { 
    type: 'button',
    class: 'btn btn-sm rounded-pill btn-label-primary mb-1',
  });
  let icon = $('<span>', {
    class: 'tf-icons bx bx-plus-circle me-1'
  });

  parent.append(container);
  container.append(button);
  button.append(icon);
  button.append(buttontext);

  return button;
}

const getBlockParent = function (category, subcategory) {
  let selector = '.block-category-'+toKebabCase(subcategory);
  if ( category.find(selector).length ) {
    return category.find(selector);
  } else {
    return addBlockParent(category, subcategory);
  }
}

const addBlockParent = function (category, subcategory) {
  let parent = $('<div>', { 
    class: 'block-category-'+toKebabCase(subcategory)
  });
  let container = $('<div>');
  let headline = $('<label>', { 
    class: 'form-label mt-3'
  });

  category.append(parent);
  parent.append(container);
  container.append(headline);
  headline.append(subcategory);

  return parent;
}

const toKebabCase = function (string) {
  return string.replace(/([a-z])([A-Z])/g, "$1-$2").replace(/[\s_]+/g, '-').toLowerCase();
}

const initActionsMenu = function () {
  if (templateEventType !== 'manual') {
    showActionsMenu();
  } else {
    $('#selectAction1_Container').hide();
    $('#selectAction1_Empty').show();
  }
}

const showActionsMenu = function () {
  $('#selectAction1').on('change', function (e) {
    if(typeof(templateVariablesEditMode.settings.template['action']) === 'undefined'){
      templateVariablesEditMode.settings.template['action'] = '';
    }

    if (e.target.value == 'email_document') {
      $('#selectAction2_container').show();
      const val = $('#selectAction2').find(":selected").val();
      if (val == 'custom') {
        $('#selectAction3_container').show();
        $('#selectAction3').trigger('change');
      } else {
        templateVariablesEditMode.settings.template.action = val;
      }
    } else {
      templateVariablesEditMode.settings.template.action = '';
      $('#selectAction2_container').hide();
      $('#selectAction3_container').hide();
    }
  });

  $('#selectAction2').on('change', function (e) {
    if (e.target.value == 'custom') {
      $('#selectAction3_container').show();
      $('#selectAction3').trigger('change');
      setTimeout(() => {
        $('#selectAction3').trigger('focus');
      }, 50);
    } else {
      templateVariablesEditMode.settings.template.action = e.target.value;
      $('#selectAction3_container').hide();
    }
  });

  $('#selectAction3').on('change', function (e) {
    if (isValidEmail(e.target.value) || e.target.value == '') {
      templateVariablesEditMode.settings.template.action = e.target.value;
      $('#selectAction3').removeClass('is-invalid');
      $('#selectAction3_message').hide();
    } else {
      templateVariablesEditMode.settings.template.action = '';
      $('#selectAction3').addClass('is-invalid');
      $('#selectAction3_message').show();
    }
  });

  $('#selectAction3').on('keyup', function (e) {
    if (isValidEmail(e.target.value) || e.target.value == '') {
      $('#selectAction3').removeClass('is-invalid');
      $('#selectAction3_message').hide();
    }
  });


  $('#selectAction3_BCC_link').on('click', function (e) {
    $('#selectAction3_BCC_link').hide();
    $('#selectAction3_BCC_container').show();
    setTimeout(() => {
      $('#selectAction3_BCC_field').trigger('focus');
    }, 50);
  });

  $('#selectAction3_BCC_field').on('change', function (e) {
    if (isValidEmail(e.target.value) || e.target.value == '') {
      templateVariablesEditMode.settings.template.actionBCC = e.target.value;
      $('#selectAction3_BCC_field').removeClass('is-invalid');
      $('#selectAction3_BCC_field_message').hide();
    } else {
      templateVariablesEditMode.settings.template.actionBCC = '';
      $('#selectAction3_BCC_field').addClass('is-invalid');
      $('#selectAction3_BCC_field_message').show();
    }
  });

  $('#selectAction3_BCC_field').on('keyup', function (e) {
    if (isValidEmail(e.target.value) || e.target.value == '') {
      $('#selectAction3_BCC_field').removeClass('is-invalid');
      $('#selectAction3_BCC_field_message').hide();
    }
  });

  // Init inputs initially
  if(typeof(templateVariablesEditMode.settings.template['action']) != 'undefined'){
    let initActionValue = templateVariablesEditMode.settings.template.action;
    if ($('#selectAction2 option[value="' + initActionValue + '"]').length > 0){
      $('#selectAction1').val('email_document').trigger('change');
      $('#selectAction2_container').show();
      $('#selectAction2').val(initActionValue).trigger('change');
    } else {
      if(initActionValue != ''){
        $('#selectAction1').val('email_document').trigger('change');
        $('#selectAction2_container').show();
        $('#selectAction2').val('custom').trigger('change');
        $('#selectAction3_container').show();
        $('#selectAction3').val(initActionValue).trigger('change');
      } else {
        // Mostly covers templateVariablesEditMode.settings.template['action'] === ''
        $('#selectAction1').val('do_nothing').trigger('change');
      }
    }
  } else {
    $('#selectAction1').val('do_nothing').trigger('change');
  }
  
  if(typeof(templateVariablesEditMode.settings.template['actionBCC']) != 'undefined'){
    let initBCC = templateVariablesEditMode.settings.template.actionBCC;
    if (initBCC != '') {
      $('#selectAction3_BCC_link').trigger('click');
      $('#selectAction3_BCC_field').val(initBCC).trigger('change');
    }
  }
}

const isValidEmail = function (input) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(input);
}

const isEditorEmpty = function () {
  if(editor.blocks.getBlocksCount() == 1) {
    if (editor.blocks.getBlockByIndex(0).isEmpty) {
      return true;
    }
  }
  return false;
}

const isBlockEmpty = function (blockIndex) {
  if (editor.blocks.getBlockByIndex(blockIndex)) {
    return editor.blocks.getBlockByIndex(blockIndex).isEmpty;
  }
  return false;
}

const insertBlock = function (type, data, tunes = {}) {
  let editorEmpty = isEditorEmpty();
  let blockEmpty = isBlockEmpty( editor.blocks.getCurrentBlockIndex() );

  // Determine where new block should be inserted (last in document or before/after current block)
  let blockposition;
  if (editor.blocks.getCurrentBlockIndex() === -1) {
    blockposition = editor.blocks.getBlocksCount(); //last in document
  } else {
    if (document.getSelection().anchorOffset === 0) {
      if (editorEmpty) {
        blockposition = editor.blocks.getCurrentBlockIndex() + 1; //after current block, since editor is empty
      } else {
        blockposition = editor.blocks.getCurrentBlockIndex(); //before current block
      }
    } else {
      blockposition = editor.blocks.getCurrentBlockIndex() + 1; //after current block
    }
  }

  // Temp save of tunes default config
  let alignmentTune = editor.configuration.tools.alignmentTune.config.default;
  let delimitermodeTune = editor.configuration.tools.delimitermodeTune.config.default;
  let marginTune = editor.configuration.tools.marginTune.config.default;
  let tableAlignmentTune = editor.configuration.tools.tableAlignmentTune.config.default;
  let tableborderTune = editor.configuration.tools.tableborderTune.config.default;

  // Set new tunes config before inserting
  setTunesConfig(tunes);
 
  // Insert block
  editor.blocks.insert(type, data, {}, blockposition, true);

  // Restore tunes config to default
  editor.configuration.tools.alignmentTune.config.default = alignmentTune;
  editor.configuration.tools.delimitermodeTune.config.default = delimitermodeTune;
  editor.configuration.tools.marginTune.config.default = marginTune;
  editor.configuration.tools.tableAlignmentTune.config.default = tableAlignmentTune;
  editor.configuration.tools.tableborderTune.config.default = tableborderTune;

  // Delete default block in editor (if editor was empty)
  let caretBlockPosition = blockposition;
  if (editorEmpty) {
    editor.blocks.delete(0);
    caretBlockPosition = blockposition-1;
  } else {
    //Delete origin block in editor (if it was empty)
    if (blockEmpty && isBlockEmpty(blockposition+1)) {
      editor.blocks.delete(blockposition+1);
    }
  }

  setTimeout(() => {
    editor.caret.setToBlock(caretBlockPosition, 'end');
  }, 50);

}

const setTunesConfig = function (tunes) {
  if (tunes["alignmentTune"]) {
    editor.configuration.tools.alignmentTune.config.default = tunes["alignmentTune"];
  }
  if (tunes["delimitermodeTune"]) {
    editor.configuration.tools.delimitermodeTune.config.default = tunes["delimitermodeTune"];
  }
  if (tunes["marginTune"]) {
    editor.configuration.tools.marginTune.config.default = tunes["marginTune"];
  }
  if (tunes["tableAlignmentTune"]) {
    editor.configuration.tools.tableAlignmentTune.config.default = tunes["tableAlignmentTune"];
  }
  if (tunes["tableborderTune"]) {
    editor.configuration.tools.tableborderTune.config.default = tunes["tableborderTune"];
  }
}

const startEditing = function (item) {
  $('#menu-item-design').removeClass('disabled');
  $('#menu-item-variables').removeClass('disabled');
  $('#menu-item-blocks').removeClass('disabled');
  $('#menu-item-actions').removeClass('disabled');

  $('#menu-hamburger-icon').show();

  $('#btn-test-template').prop("disabled", false);
  $('#btn-save-changes').prop("disabled", false);

  editor.readOnly.toggle();
};

const onMenuClick = function (item) {
  if (!$('#menu-item-' + item).hasClass('disabled')) {
    if ($('#template-toolbox').is(':hidden')) {
      $('#template-toolbox').show('fast', 'linear', function () {
        showToolbox(item);
      });
    } else {
      if ($('#menu-item-' + item).hasClass('active')) {
        hideAllMenuItems();
        $('#template-toolbox').hide('fast', 'linear');
      } else {
        showToolbox(item);
      }
    }
  }
};

const showToolbox = function (item) {
  hideAllMenuItems();
  $('#menu-item-' + item).addClass('active');
  $('#template-toolbox-' + item).show();
};

const hideAllMenuItems = function () {
  $('.menu-item').removeClass('active');
  $('.template-toolbox').hide();
};

const initTestButton = function () {

  $('#btn-test-template').on('click', function (e) {
    if (!editor.readOnly.isEnabled) {
      editor.save().then((editorData) => {

        disableSaveButton();
        Sidebar.inputsDisabled(true);

        //Clean editor data
        TemplateEditor.cleanEditorData(editorData);

        //Temp save of editor data
        editorTempData = Lodash.cloneDeep(editorData);

        //Add highlighting to variables and run Handlebars
        TemplateEditor.preview(editorData, templateVariablesEditMode);

        //Update editor data and switch to readonly mode
        editor.render(editorData).then(() => {
          editor.readOnly.toggle();
        });

        resetErrorMessage();
        setTestButton('close');

        // For DEV mode: Preview data in container
        if (window.location.hostname == 'localhost') {
          $('#editorPreviewJSON .card-text').text( JSON.stringify(editorTempData) );
          $('#editorPreviewJSON').show();
        }

      }).catch((error) => {
        showErrorMessage(formatErrorMessage(error), 'code');
        setTestButton('fix');
      });
    } else {
      //Restore data in editor
      editor.render(editorTempData).then(() => {
        editor.readOnly.toggle();
        editorTempData = {};
      });

      Sidebar.inputsDisabled(false);
      setTestButton();
      enableSaveButton();

        // For DEV mode: Restore preview container
        $('#editorPreviewJSON').hide();
    }
  });

  $('#btn-toggle-variable-marking').parent().popover({
    content: 'Show/hide variable marking',
    trigger: 'hover',
    placement: 'bottom',
    template: '<div class="popover popover-dark" role="tooltip"><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
  }).popover('enable');

  $('#btn-toggle-variable-marking').on('click', function (e) {
    $(this).toggleClass('selected');
    if ($(this).hasClass('selected')) {
      $('.variable-marker, .variable-marker-warning').addClass('hide-marking');
    } else {
      $('.variable-marker, .variable-marker-warning').removeClass('hide-marking');
    }
  });

};

const setTestButton = function (mode) {

  $('#btn-test-template').removeClass('btn-secondary');
  $('#btn-test-template').removeClass('btn-danger');

  switch (mode) {
    case 'fix':
      $('#btn-test-template').html('Fix and try again');
      $('#btn-test-template').addClass('btn-danger');
      break;
    case 'close':
      $('#btn-test-template').html('Close test');
      $('#btn-test-template').addClass('btn-secondary');
      $('#test-template-settings').show();
      $('#test-template-settings').addClass('show-btns');
      break;
    default:
      $('#btn-test-template').html('Test template');
      $('#btn-test-template').addClass('btn-secondary');
      $('#test-template-settings').hide();
      $('#test-template-settings').removeClass('show-btns');
      $('#btn-toggle-variable-marking').removeClass('selected');
  }
}

const disableSaveButton = function () {
  $('#btn-save-changes').prop("disabled", true);
  $('#btn-save-changes').parent().popover('enable');
}

const enableSaveButton = function () {
  $('#btn-save-changes').prop("disabled", false);
  $('#btn-save-changes').parent().popover('disable');
}

const formatErrorMessage = function (error) {
  var encodedError = error.message.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
    return '&#' + i.charCodeAt(0) + ';';
  });
  console.log(encodedError);

  //Adjust error message content and add line breaks
  let errorMessagelines = encodedError.split('\n')
  let errorMessage = "";
  for (var i = 0; i < errorMessagelines.length; i++) {
    if (!errorMessagelines[i].includes("Expecting ")) {
      errorMessage = errorMessage + errorMessagelines[i].replace(" on line 1:", ":") + "<br/>";
    }
  }

  return errorMessage;
}

const showErrorMessage = function (errMsg, type) {
  if (type == 'code') {
    $('#error-message').addClass('error-message-code');
  } else {
    $('#error-message').removeClass('error-message-code');
  }
  $('#error-message').html(errMsg);

  // Show error message in Toast
  toastDispose(toastError);
  toastError = new Toast(document.querySelector('.toast-ex'));
  toastError.show();
}

const resetErrorMessage = function () {
  $('#error-message').html("");
  toastDispose(toastError);
}

const toastDispose = function (toast) {
  if (toast && toast._element !== null) {
    toast.dispose();
  }
}


/**
 * Sidebar events
 */

const initSidebarEvents = function (themeData) {
  const edit_sidebar = document.getElementById("template-toolbox");

  edit_sidebar.addEventListener('sidebar_setting_locale_changed', function (e) {
    templateVariablesEditMode.settings.template.locale = e.detail;
  });

  edit_sidebar.addEventListener('sidebar_setting_timezone_changed', function (e) {
    templateVariablesEditMode.settings.template.date_format.timeZone = e.detail;
  });

  edit_sidebar.addEventListener('sidebar_setting_hidedecimals_changed', function (e) {
    templateVariablesEditMode.settings.template.hide_decimals_when_whole_number = e.detail;
  });

  edit_sidebar.addEventListener('sidebar_theme_changed', function (e) {
    //console.log( e.detail.color1, e.detail.color2, e.detail.color3, e.detail.color4 );

    let styleHeading = "#editorjs h1, #editorjs h2, #editorjs h3, #editorjs h4, #editorjs h5, #editorjs h6 {\n  color: " + e.detail.color1 + " !important;\n}\n";
    let styleTableHeading = "#editorjs .tc-table--heading .tc-row:first-child {\n  color: " + e.detail.color2 + " !important;\n}\n";
    let styleTableBorder = "#editorjs .tc-table, #editorjs .tc-row, #editorjs .tc-cell {\n  border-color: " + e.detail.color3 + " !important;\n}\n";
    let styleDelimiter = "#editorjs .ce-delimiter {\n  color: " + e.detail.color4 + " !important;\n  border-color: " + e.detail.color4 + " !important;\n}\n";

    addStyle(styleHeading + styleTableHeading + styleTableBorder + styleDelimiter, "custom-style-colors");
    templateVariablesEditMode.settings.template['theme'] = e.detail.theme;
  });

  // Init theme settings if theme is in template settings
  const themeKey = templateVariablesEditMode.settings.template['theme'];
  let detailObject = {};
  if(!themeKey || themeKey === '' || themeKey === 'default'){
    detailObject = {
      ...defaultStyle,
      theme: 'default',
    };
  } else {
    detailObject = {
      theme: themeKey,
      color1: themeData[themeKey]['color1'],
      color2: themeData[themeKey]['color2'],
      color3: themeData[themeKey]['color3'],
      color4: themeData[themeKey]['color4'],
    }
  }
  edit_sidebar.dispatchEvent(new CustomEvent("sidebar_theme_changed", {
    detail: detailObject
  }));
}

const addStyle = (styles, styleId) => {
  let css_container = document.getElementById(styleId);
  if (css_container) {
    /* Update style element */
    css_container.innerText = styles;
  } else {
    /* Create style element */ 
    const css = document.createElement('style'); 
    css.type = 'text/css';
    css.id = styleId;
    css.appendChild(document.createTextNode(styles)); 
    document.getElementsByTagName("head")[0].appendChild(css); 
  }
}