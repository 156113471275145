const LocalesList = require('./getAllLocales.js');
const CountriesList = require('./getAllCountries.js');

'use strict';
function init() {

    const select2 = $('.select2');
    const select2Icons = $('.select2-icons');
    const select2Colors = $('.select2-colors');

    // Select2
    // --------------------------------------------------------------------

    // Default
    if (select2.length) {
        select2.each(function () {
            var $this = $(this);
            $this.wrap('<div class="position-relative"></div>').select2({
                placeholder: 'Select value',
                dropdownParent: $this.parent()
            });
        });
    }

    // Select2 Icons
    if (select2Icons.length) {
        // custom template to render icons
        function renderIcons(option) {
            if (!option.id) {
                return option.text;
            }
            var $icon = "<i class='" + $(option.element).data('icon') + " me-2'></i>" + option.text;

            return $icon;
        }
        select2Icons.wrap('<div class="position-relative"></div>').select2({
            templateResult: renderIcons,
            templateSelection: renderIcons,
            escapeMarkup: function (es) {
                return es;
            }
        });
    }

    // Select2 Colors
    if (select2Colors.length) {
        // custom template to render icons and color
        function renderColors(option) {
            if (!option.id) {
                return option.text;
            }
            var $icon =
                "<i class='" +
                $(option.element).data('icon') +
                " me-2' style='color: " +
                $(option.element).data('color') +
                "'></i>" +
                option.text;

            return $icon;
        }
        select2Icons.wrap('<div class="position-relative"></div>').select2({
            templateResult: renderColors,
            templateSelection: renderColors,
            escapeMarkup: function (es) {
                return es;
            },
            minimumResultsForSearch: -1 //hide search field
        });
    }

    $(document).on('select2:open', function(e) {
        document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`).focus(); //focus search field on open
    });

}

function populateLocaleSelect(element) {
    const allLocales = LocalesList.getAllLocales();

    $.each(allLocales, function (i, item) {
        $(element).append($('<option>', {
            value: item.iso_639_with_country,
            text : item.english_name
        }));
    });
    $(element).val($('#template_company_locale_before').val());
}

function populateTimezoneSelect(element) {
    const allTimezones = Intl.supportedValuesOf('timeZone');

    $.each(allTimezones, function (i, item) {
        $(element).append($('<option>', {
            value: item,
            text : item.replaceAll('_', ' ')
        }));
    });

    $(element).val($('#template_company_timezone_before').val());
}

function populateCountrySelect(element) {
    const allCountries = CountriesList.getAllCountries();

    $.each(allCountries, function (i, item) {
        $(element).append($('<option>', {
            value: item.code,
            text : item.name
        }));
    });
    $(element).val($('#template_address_country_before').val());
}

module.exports = { init, populateLocaleSelect, populateTimezoneSelect, populateCountrySelect };