const JSONViewer = require('./json-viewer');
const SidebarSelects = require('./sidebarSelects.js');
const {defaultStyle} = require("../custom-styles");

/**
 * Variables to highlight/shortlist
 */
const json_stripe_intent_highlight = [
  "amount",
  "amount_received",
  "canceled_at",
  "cancellation_reason",
  "created", "currency",
  "description",
  "latest_charge",
  "metadata"
];

const json_stripe_charge_highlight = [
  "amount",
  "created", "currency",
  "description",
  "billing_details",
  "customer",
  "metadata"
];

const json_stripe_checkout_session_highlight = [
  "amount_total",
  "amount_subtotal",
  "created", "currency",
  "description",
  "billing_details",
  "customer",
  "metadata",
  "line_items",
  "total_details",
];

const json_stripe_customer_highlight = [
  "address",
  "balance",
  "created",
  "currency",
  "description",
  "discount",
  "email",
  "metadata",
  "name",
  "phone"
];

const json_stripe_invoice_highlight = [
  "amount_due",
  "amount_paid",
  "created",
  "currency",
  "customer_address",
  "customer_email",
  "customer_name",
  "customer_phone",
  "metadata",
  "total"
];

/**
 * Init sidebar
 */
var json_settings = {};
var json_stripe_intent = {};
var json_stripe_customer = {};
var json_stripe_invoice = {};
var json_stripe_charge = {};
var json_stripe_checkout_session = {};
var json_payment_object = {};

var jsonViewer_settings;
var jsonViewer_stripe_intent;
var jsonViewer_stripe_customer;
var jsonViewer_stripe_invoice;
var jsonViewer_stripe_charge;
var jsonViewer_stripe_checkout_session;
var jsonViewer_payment_object;

let blocktype;
let locale;
let timezone;
let hideDecimal;
let selectTheme;

let sidebar;

function init(templateVariables, themeData) {

  /** Init variables */
  json_settings["company"] = templateVariables.settings.company;

  json_stripe_intent = highlightVariables(templateVariables.stripe.intent, json_stripe_intent_highlight);
  json_stripe_customer = highlightVariables(templateVariables.stripe.customer, json_stripe_customer_highlight);
  json_stripe_invoice = highlightVariables(templateVariables.stripe.invoice, json_stripe_invoice_highlight);
  json_stripe_charge = highlightVariables(templateVariables.stripe.charge, json_stripe_charge_highlight);
  json_stripe_checkout_session = highlightVariables(templateVariables.stripe.checkout_session, json_stripe_checkout_session_highlight);
  json_payment_object = highlightVariables(templateVariables.payment, [], true);

  /** Init jsonViewer */
  jsonViewer_settings = new JSONViewer();
  jsonViewer_stripe_intent = new JSONViewer();
  jsonViewer_stripe_customer = new JSONViewer();
  jsonViewer_stripe_invoice = new JSONViewer();
  jsonViewer_stripe_charge = new JSONViewer();
  jsonViewer_stripe_checkout_session = new JSONViewer();
  jsonViewer_payment_object = new JSONViewer();

  sidebar = document.getElementById("template-toolbox");

  const code_container_settings = document.getElementById("code-container-settings");
  const code_container_stripe_intent = document.getElementById("code-container-stripe-intent");
  const code_container_stripe_customer = document.getElementById("code-container-stripe-customer");
  const code_container_stripe_invoice = document.getElementById("code-container-stripe-invoice");
  const code_container_stripe_charge = document.getElementById("code-container-stripe-charge");
  const code_container_stripe_checkout_session = document.getElementById("code-container-stripe-checkout-session");
  const code_container_payment_object = document.getElementById("code-container-payment-object");

  blocktype = document.getElementById("selectBlocktype");
  locale = document.getElementById("selectLocale");
  timezone = document.getElementById("selectTimezone");
  hideDecimal = document.getElementById("hideDecimalsSwitch");
  selectTheme = document.getElementById('selectTheme');

  code_container_settings.appendChild(jsonViewer_settings.getContainer());

  if(templateVariables.stripe.intent != null){
    code_container_stripe_intent.appendChild(jsonViewer_stripe_intent.getContainer());
  } else {
    document.getElementById('accordion-stripe-intent-card').setAttribute("style", "display: none;");
  }

  if(templateVariables.stripe.customer != null){
    code_container_stripe_customer.appendChild(jsonViewer_stripe_customer.getContainer());
  } else {
    document.getElementById('accordion-stripe-customer-card').setAttribute("style", "display: none;");
  }

  if(templateVariables.stripe.invoice != null){
    code_container_stripe_invoice.appendChild(jsonViewer_stripe_invoice.getContainer());
  } else {
    document.getElementById('accordion-stripe-invoice-card').setAttribute("style", "display: none;");
  }

  if(templateVariables.stripe.charge != null){
    code_container_stripe_charge.appendChild(jsonViewer_stripe_charge.getContainer());
  } else {
    document.getElementById('accordion-stripe-charge-card').setAttribute("style", "display: none;");
  }

  if(templateVariables.stripe.checkout_session != null){
    code_container_stripe_checkout_session.appendChild(jsonViewer_stripe_checkout_session.getContainer());
  } else {
    document.getElementById('accordion-stripe-checkout-session-card').setAttribute("style", "display: none;");
  }

  if(templateVariables.payment != null){
    code_container_payment_object.appendChild(jsonViewer_payment_object.getContainer());
  } else {
    document.getElementById('accordion-payment-object-card').setAttribute("style", "display: none;");
  }

  jsonViewer_settings.setVariablePrefix("settings.");
  jsonViewer_stripe_intent.setVariablePrefix("stripe.intent.");
  jsonViewer_stripe_customer.setVariablePrefix("stripe.customer.");
  jsonViewer_stripe_invoice.setVariablePrefix("stripe.invoice.");
  jsonViewer_stripe_charge.setVariablePrefix("stripe.charge.");
  jsonViewer_stripe_checkout_session.setVariablePrefix("stripe.checkout_session.");
  jsonViewer_payment_object.setVariablePrefix("payment.");

  jsonViewer_settings.showJSON(json_settings, null, 5);
  jsonViewer_stripe_intent.showJSON(json_stripe_intent, null, 5, true);
  jsonViewer_stripe_customer.showJSON(json_stripe_customer, null, 5, true);
  jsonViewer_stripe_invoice.showJSON(json_stripe_invoice, null, 5, true);
  jsonViewer_stripe_charge.showJSON(json_stripe_charge, null, 5, true);
  jsonViewer_stripe_checkout_session.showJSON(json_stripe_checkout_session, null, 5, true);
  jsonViewer_payment_object.showJSON(json_payment_object, null, 5, true);

  /*
  document.addEventListener('jsonviewer_change', function(e) {
  });
  */

  sidebar.addEventListener('jsonviewer_variable_click', function(e) {
    copyToClipboard(e.target, e.detail.copyContent);
  });


  /** Init the rest */
  SidebarSelects.populateBlocktypeSelect(blocktype);
  SidebarSelects.populateLocaleSelect(locale);
  SidebarSelects.populateTimezoneSelect(timezone);

  initSwitches();
  initCopyToClipboard();
  initSettingSelects(templateVariables);
  initThemeSelect(templateVariables, themeData);

  SidebarSelects.init();
}

/**
 * Init functions
 */

function initSwitches() {
  $('.show-all-switch').on('change', function () {
    this.checked ? showAllVariables(this.name) : hideAllVariables(this.name);
  });
}

function initCopyToClipboard() {
  $('.custom-function-code').on('click', function (e) {
    copyToClipboard( e.target, e.target.innerHTML );
  });
}

function initSettingSelects(templateVariables) {

  if(templateVariables.settings.template.locale == null){
    templateVariables.settings.template.locale =
        templateVariables.settings.company?.locale !==  null &&
        templateVariables.settings.company?.locale !==  '' ? templateVariables.settings.company?.locale : 'en-US';
  }
  if(templateVariables.settings.template.timezone == null){
    templateVariables.settings.template.timezone =
        templateVariables.settings.company?.timezone !==  null &&
        templateVariables.settings.company?.timezone !==  ''? templateVariables.settings.company?.timezone : 'America/New_York';
  }
  locale.value = templateVariables.settings.template.locale;
  timezone.value = templateVariables.settings.template.timezone;

  if(templateVariables.settings?.date_format){
    templateVariables.settings.date_format['timeZone'] = timezone.value;
  }

  hideDecimal.checked = templateVariables.settings.template.hide_decimals_when_whole_number;

  $(locale).on('change', function (e) {
    templateVariables.settings.template.locale = e.target.value;
    let event = new CustomEvent("sidebar_setting_locale_changed", {detail: e.target.value});
    sidebar.dispatchEvent(event);
  });

  $(timezone).on('change', function (e) {
    templateVariables.settings.template.timezone = e.target.value;
    let event = new CustomEvent("sidebar_setting_timezone_changed", {detail: e.target.value});
    sidebar.dispatchEvent(event);
  });

  $(hideDecimal).on('change', function () {
    const value = $(hideDecimal).is(':checked');
    let event = new CustomEvent("sidebar_setting_hidedecimals_changed", {detail: value});
    templateVariables.settings.template.hide_decimals_when_whole_number = value;
    sidebar.dispatchEvent(event);
  });

}

function initThemeSelect(templateVariables, themeData) {

  //Populate select with theme data
  $("option[value='default']", selectTheme).text("Default theme");
  $("option[value='default']", selectTheme).attr("data-theme", 'default');
  $("option[value='default']", selectTheme).attr("data-color-1", defaultStyle.color1);
  $("option[value='default']", selectTheme).attr("data-color-2", defaultStyle.color2);
  $("option[value='default']", selectTheme).attr("data-color-3", defaultStyle.color3);
  $("option[value='default']", selectTheme).attr("data-color-4", defaultStyle.color4);

  $("option[value='theme1']", selectTheme).text(themeData.theme1.name);
  $("option[value='theme1']", selectTheme).attr("data-theme", 'theme1');
  $("option[value='theme1']", selectTheme).attr("data-color-1", themeData.theme1.color1);
  $("option[value='theme1']", selectTheme).attr("data-color-2", themeData.theme1.color2);
  $("option[value='theme1']", selectTheme).attr("data-color-3", themeData.theme1.color3);
  $("option[value='theme1']", selectTheme).attr("data-color-4", themeData.theme1.color4);

  $("option[value='theme2']", selectTheme).text(themeData.theme2.name);
  $("option[value='theme2']", selectTheme).attr("data-theme", 'theme2');
  $("option[value='theme2']", selectTheme).attr("data-color-1", themeData.theme2.color1);
  $("option[value='theme2']", selectTheme).attr("data-color-2", themeData.theme2.color2);
  $("option[value='theme2']", selectTheme).attr("data-color-3", themeData.theme2.color3);
  $("option[value='theme2']", selectTheme).attr("data-color-4", themeData.theme2.color4);

  $("option[value='theme3']", selectTheme).text(themeData.theme3.name);
  $("option[value='theme3']", selectTheme).attr("data-theme", 'theme3');
  $("option[value='theme3']", selectTheme).attr("data-color-1", themeData.theme3.color1);
  $("option[value='theme3']", selectTheme).attr("data-color-2", themeData.theme3.color2);
  $("option[value='theme3']", selectTheme).attr("data-color-3", themeData.theme3.color3);
  $("option[value='theme3']", selectTheme).attr("data-color-4", themeData.theme3.color4);

  //On select change
  $(selectTheme).val(templateVariables.settings.template['theme'] || 'default');
  $(selectTheme).on("change", function(e) {
    let colorobj = {
      "theme" : $("option:selected", e.target).data('theme'),
      "color1" : $("option:selected", e.target).data('color-1'),
      "color2" : $("option:selected", e.target).data('color-2'),
      "color3" : $("option:selected", e.target).data('color-3'),
      "color4" : $("option:selected", e.target).data('color-4')
    };
    let event = new CustomEvent("sidebar_theme_changed", {detail: colorobj});
    sidebar.dispatchEvent(event);
  });
}



/**
 * Other functions
 */

function highlightVariables(jsonVariables, arrHighlight, all = false) {

  if(jsonVariables == null) return {};

  let newVariables = {};

  Object.keys(jsonVariables).forEach(function(key) {
    if (arrHighlight.includes(key) || all) {
      newVariables[key + "__HIGHLIGHT"] = jsonVariables[key];
    } else {
      newVariables[key] = jsonVariables[key];
    }
  });
  return newVariables;
}

function showAllVariables(listName) {
  switch (listName) {
    case "stripe.intent":
      jsonViewer_stripe_intent.showJSON(json_stripe_intent, null, 5, false);
      break;
    case "stripe.customer":
      jsonViewer_stripe_customer.showJSON(json_stripe_customer, null, 5, false);
      break;
    case "stripe.invoice":
      jsonViewer_stripe_invoice.showJSON(json_stripe_invoice, null, 5, false);
      break;
    case "stripe.charge":
      jsonViewer_stripe_charge.showJSON(json_stripe_charge, null, 5, false);
      break;
    case "stripe.checkout_session":
      jsonViewer_stripe_checkout_session.showJSON(json_stripe_checkout_session, null, 5, false);
      break;
  }
}

function hideAllVariables(listName) {
  switch (listName) {
    case "stripe.intent":
      jsonViewer_stripe_intent.showJSON(json_stripe_intent, null, 5, true);
      break;
    case "stripe.customer":
      jsonViewer_stripe_customer.showJSON(json_stripe_customer, null, 5, true);
      break;
    case "stripe.invoice":
      jsonViewer_stripe_invoice.showJSON(json_stripe_invoice, null, 5, true);
      break;
    case "stripe.charge":
      jsonViewer_stripe_charge.showJSON(json_stripe_charge, null, 5, true);
      break;
    case "stripe.checkout_session":
      jsonViewer_stripe_checkout_session.showJSON(json_stripe_checkout_session, null, 5, true);
      break;
  }
}

function inputsDisabled(value) {
  blocktype.disabled = value;

  locale.disabled = value;
  timezone.disabled = value;
  hideDecimal.disabled = value;
  selectTheme.disabled = value;

  $('.blocks-container button').prop('disabled', value);
  $('#selectAction1').prop('disabled', value);
  $('#selectAction2').prop('disabled', value);
  $('#selectAction3').prop('disabled', value);
}

function copyToClipboard(element, copyContent) {
  //Check clipboard permissions
  navigator.permissions.query({
    name: 'clipboard-write'
  }).then((result) => {
    if (result.state == "granted" || result.state == "prompt") {
      doCopy();
    }
  }).catch((err) => {
    console.log("Couldn't query clipboard permission", err);
  });

  const doCopy = async () => {
    try {
      await navigator.clipboard.writeText(copyContent);
      showTooltip();
      console.log('Copied to clipboard');
    } catch (err) {
      console.error('Failed to copy to clipboard: ', err);
    }
  }

  const showTooltip = function () {
    $(element).popover('dispose').popover({
      content: 'Copied to clipboard!',
      trigger: 'manual',
      placement: 'top',
      template: '<div class="popover popover-dark" role="tooltip"><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    }).popover('show');
    setTimeout(() => {
      $(element).popover('dispose');
    }, 600)
  }
}

module.exports = { init, open, close, inputsDisabled };
