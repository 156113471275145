const langmap = require("langmap");

function getAllLocales() {
  const allLocales = [];
  for (const code in langmap) {
    if (!Object.prototype.hasOwnProperty.call(langmap, code)) continue;

    if (code.includes('-')) {
      const item = {
        iso_639_with_country: code,
        native_name: langmap[code].nativeName,
        english_name: langmap[code].englishName,
      };

      allLocales.push(item);
    }
  }

  return allLocales;
}

module.exports = { getAllLocales };