const EditorJS = require('@editorjs/editorjs');
const Header = require('@editorjs/header');
const Image = require('@editorjs/image');
const Delimiter = require('@editorjs/delimiter');
const List = require('@editorjs/list');
const Table = require('@editorjs/table');
const Marker = require('@editorjs/marker');
import { DirectUpload} from '@rails/activestorage'

const TableBorderTuneTool = require('./editorjs-blocktunes/table-border');
const DelimiterModeTuneTool = require('./editorjs-blocktunes/delimiter-mode');
const MarginTuneTool = require('./editorjs-blocktunes/margin');
const AlignmentTuneTool = require('./editorjs-blocktunes/alignment');
const TableAlignmentTuneTool = require('./editorjs-blocktunes/table-alignment');

const HandlebarsModule = require('./handlebars');

//Handlebar init
var Handlebars;
const errorMessages = {
    "helper_function_not_found": "no such function",
    "missing_or_empty_variable": "missing/empty"
};

/**
* Init editor.js
*/
function init(template_mode, templateData, templateVariables, authenticity_token, onReadyCB) {
    Handlebars = HandlebarsModule.getHandlebars(templateVariables);

    var editorInitData;
    var editorReadOnlyMode = true;

    var loopRowVariables = getLoopRowVariables(templateVariables);

    //Set template data
    if (template_mode == 'use') {
        iterateTemplateUseMode(templateData, templateVariables);
        editorInitData = templateData;
    } else if (template_mode == 'edit') {
        editorInitData = templateData;
        editorReadOnlyMode = true;
    }

    //Tags to be allowed in tables in editor (br tag not default otherwise)
    Object.defineProperty(Table, 'sanitize',  {
      get() {
        return {
          br: true,
          'editorjs-style': true,
          code: true,
          mark: true,
          b: true,
          i: true,
          a: true,
          div: {}
        };
      }
    });

    //Allow following classes for Marker
    Object.defineProperty(Marker, 'sanitize',  {
      get() {
        return {
            mark: {
                class: ['cdx-marker', 'variable-marker', 'variable-marker-warning', 'variable-marker-error']
            }
        };
      }
    });

    var editor = new EditorJS({

      readOnly: editorReadOnlyMode, //enable/disable the read only mode
      holder: 'editorjs', //wrapper of Editor

      /**
       * Tools list
       */
      tools: {

        paragraph: {
          inlineToolbar: true,
          config: {
            placeholder: 'Add blocks or enter text...',
            preserveBlank: true
          },
          tunes: ['alignmentTune','marginTune']
        },

        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: 'Header'
          },
          shortcut: 'CMD+SHIFT+H',
          tunes: ['alignmentTune','marginTune']
        },
        
        image: {
          class:  Image,
          inlineToolbar: true,
          config: {
            uploader:{
              uploadByFile: uploadFile
            },
            additionalRequestHeaders: {
              'X-CSRF-Token': authenticity_token,
            }
          },
          tunes: ['marginTune']
        },

        list: {
          class: List,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+L',
          tunes: ['marginTune']
        },

        delimiter: {
          class: Delimiter,
          tunes: ['delimitermodeTune']
        },

        table: {
          class: Table,
          config:{
            loopRowOptions: loopRowVariables,
            loopRow: {
              row: 0,
              array: (loopRowVariables.length > 0 ? loopRowVariables[0] : '')
            }
          },
          inlineToolbar: true,
          shortcut: 'CMD+ALT+T',
          tunes: ['tableAlignmentTune','tableborderTune','marginTune']
        },

        alignmentTune: {
          class:AlignmentTuneTool,
          config:{
            default: "left",
          },
        },

        tableAlignmentTune: {
          class:TableAlignmentTuneTool,
          config:{
            default: "left",
          },
        },

        tableborderTune: {
          class:TableBorderTuneTool,
          config:{
            default: "show",
          },
        },

        delimitermodeTune: {
          class:DelimiterModeTuneTool,
          config:{
            default: "default",
          },
        },

        marginTune: {
          class:MarginTuneTool,
          config:{
            default: "default",
          },
        },

        marker: {
          class:  Marker,
          shortcut: 'CMD+SHIFT+M'
        },

      },

      defaultBlock: 'paragraph',

      /**
       * Initial Editor data
       */
      data: editorInitData,

      onReady: function(){
        console.log('editor loaded');

        let event = new Event("editor_ready");
        document.dispatchEvent(event);

        if(onReadyCB) onReadyCB();
      },
      onChange: function(api, event) {
        //console.log('something changed', event);
      }
    });

    return editor;
}

function getLoopRowVariables(templateVariables) {
  if (templateVariables?.stripe?.invoice !== null) {
    return ['stripe.invoice.lines.data','stripe.invoice.custom_fields', 'stripe.invoice.customer_tax_ids','stripe.invoice.default_tax_rates','stripe.invoice.discounts','stripe.invoice.total_discount_amounts','stripe.invoice.total_tax_amounts'];
  }
  if (templateVariables?.stripe?.checkout_session !== null) {
    return ['stripe.checkout_session.line_items.data','stripe.checkout_session.custom_fields', 'stripe.checkout_session.customer_details.tax_ids','stripe.checkout_session.payment_intent.payment_method_types','stripe.checkout_session.payment_method_types','stripe.checkout_session.shipping_options','stripe.checkout_session.total_details.breakdown.discounts','stripe.checkout_session.total_details.breakdown.taxes'];
  }
  if (templateVariables?.stripe?.intent !== null) {
    return ['stripe.intent.payment_method_types'];
  }
  return [];
}

//Iterate through templatedata to run Handlebars
function iterateTemplateUseMode(obj, templateVariables) {
    Object.keys(obj).forEach(function(key) {
        if (obj[key] !== null) {
            if (key === "blocks") {
              checkRowLoop( obj[key], templateVariables );
            }
            if (typeof obj[key] === "string") {
                obj[key] = runHandlebars( obj[key], templateVariables );
            } else if (typeof obj[key] === "object") {
                iterateTemplateUseMode( obj[key], templateVariables );
            }
        }
    });
}

function runHandlebars(templateItem, templateVariables) {
    let handlebarTemplate = Handlebars.compile(templateItem);
    let handlebarResult;

    handlebarResult = handlebarTemplate(templateVariables);

    try {
      handlebarResult = handlebarTemplate(templateVariables);
    } catch (error) {
      var encodedError = error.message.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
        return '&#' + i.charCodeAt(0) + ';';
      });
      console.log(encodedError);
      handlebarResult = templateItem;
    }

    return handlebarResult;
}

//Add rows
function addRow(tableRow, loopRef, index) {
  let start = "{{#with " + loopRef +  ".["+index+"]}}";
  let end = "{{/with}}";
  let newTableRow = [];
  for (var i=0; i<tableRow.length; i++) {
    newTableRow.push( start + tableRow[i] + end );
  }
  return newTableRow;
}

//Do row loop
function doRowLoop(tableContent, rowIndex, loopRef, numArrayItems) {
  let newContent = [];
  for (var i=0; i<tableContent.length; i++) {
    if (i != rowIndex) {
      newContent.push( tableContent[i] );
    } else {
      for (var k=0; k<numArrayItems; k++) {
        newContent.push( addRow( tableContent[i], loopRef, k ) );
      }
    }
  }

  return newContent;
}

//Return number of items in loop array
function countArrayItems(loopRef, templateVariables) {
  let lines = loopRef.split('.').reduce((obj, key) => obj[key] ? obj[key] : 0, templateVariables);
  return (lines == 0) ? 0 : lines.length;
}

//Check for row loop in table
function checkRowLoop(blocks, templateVariables) {
  blocks.forEach((block) => {
    if (block.type == "table" && block.data?.loopRow) {
      if (block.data.loopRow.row > 0) {
        let numArrayItems = countArrayItems(block.data.loopRow.array, templateVariables);
        if (numArrayItems > 0) {
          block.data.content = doRowLoop(block.data.content, block.data.loopRow.row-1, block.data.loopRow.array, numArrayItems);
        }
      }
    }
  });
}

//Iterate through templatedata to add highlighting
function iterateTemplateEditMode(obj, templateVariablesEditMode) {
    Object.keys(obj).forEach(function(key) {
        if (obj[key] !== null) {
            if (key === "blocks") {
              checkRowLoop( obj[key], templateVariablesEditMode );
            }
            if (typeof obj[key] === "string") {
                obj[key] = doHighlighting( obj[key] ); //highlight variables
                obj[key] = runHandlebars( obj[key], templateVariablesEditMode ); //perform Handlebars templating
                obj[key] = checkHighlighting( obj[key] ); //adjust highlighting of variables after Handlebars output
            } else if (typeof obj[key] === "object") {
                iterateTemplateEditMode( obj[key], templateVariablesEditMode );
            }
        }
    });
}

function doHighlighting(templateItem) {
    let str = templateItem;

    const _isLetter = function (str) {
      return str.length === 1 && str.match(/[a-z]/i);
    }

    //highlight all variables
    str = str.replaceAll(/\{\{(.+?)\}\}/g, function(m, content) {
        //check first char to catch #each, #with, etc
        if (_isLetter(content.charAt(0))) {
            return '<mark class=\"variable-marker\">{{' + content + '}}</mark>';
        } else {
            return '{{' + content + '}}';
        }
    });

    return str;
}

function checkHighlighting(templateItem) {
    let errorMessages = {
      "missing_or_empty_variable": "missing/empty"
    };
    let str = templateItem;

    //highlight empty/missing tags
    str = str.replaceAll("<mark class=\"variable-marker\"></mark>", "<mark class=\"variable-marker-warning\">" + errorMessages.missing_or_empty_variable + "</mark>");

    //highlight variables with object or array of objects
    str = str.replaceAll(/\<mark class=\"variable-marker\"\>\[object Object(.+?)\<\/mark\>/g, function(m, content) {
        return '<mark class=\"variable-marker-warning\">[object Object' + content + '</mark>';
    });

    return str;
}

//Iterate through templatedata to clean up
function iterateAndCleanTemplateData(obj) {
  Object.keys(obj).forEach(function(key) {
    if (obj[key] !== null) {
      if (typeof obj[key] === "string") {
        obj[key] = cleanTemplateString( obj[key] );
      } else if (typeof obj[key] === "object") {
        iterateAndCleanTemplateData( obj[key] );
      }
    }
  });
}

function cleanTemplateString(editorString) {
  editorString = editorString.replaceAll(/\{{3,}/g, '{{').replaceAll(/\}{3,}/g, '}}'); //replace multiple {{ or }}
  editorString = editorString.replaceAll(/<div>/gi,'<br>').replaceAll(/<\/div>/gi,''); //replace divs with br (to handle soft linebreaks in i.e. Safari)
  return editorString;
}

function cleanEditorData(obj) {
  iterateAndCleanTemplateData(obj);
}

function preview(obj, templateVariablesEditMode) {
    iterateTemplateEditMode(obj, templateVariablesEditMode);
}

/**
 * Function to upload to rails
 * @param file
 * @returns {Promise<unknown>}
 */
function uploadFile(file) {
  const url = "/rails/active_storage/direct_uploads";
  const upload = new DirectUpload(file, url);

  return new Promise(function (resolve, reject) {
    upload.create((error, blob) => {
      if (error) {
        reject(error);
      } else {
        fetch(`/blocks/get_image_url?attachable_sgid=${blob.attachable_sgid}`)
          .then((response) => response.json())
          .then((imageUrlResp) => {
            console.log(imageUrlResp);
            resolve({
              success: 1,
              file: {
                url: imageUrlResp.url,
                attachable_sgid: blob.attachable_sgid,
              },
            });
          });
      }
    });
  });
}


module.exports = { init, preview, cleanEditorData };
