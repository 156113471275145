import { Controller } from "@hotwired/stimulus"
import { initSettings } from "../settings/settings";

// Connects to data-controller="settings"
export default class extends Controller {
  connect() {
    initSettings();
    addEventListener("turbo:frame-render",
      (e) => { initSettings(); })
  }
}