import { Controller } from "@hotwired/stimulus"
import { initEditor } from "../template-editor/template-editor-edit";
import {
  defaultStripeCustomerJSON, defaultStripeInvoiceJSON,
  defaultStripePaymentIntentJSON,
  defaultTemplateJSON,
  defaultTemplateSettings,
  extractCompanySettings
} from "./template-utils";

// Connects to data-controller="template-editor"
export default class extends Controller {
  static targets = ['body', 'settings', 'form']

  connect() {
    let initialJson = ''
    try {
      initialJson = JSON.parse(this.bodyTarget.value);
    } catch (e) {
      // If this doeesn't work, let's use a basic starter
      initialJson = defaultTemplateJSON();
    }

    let template_settings = ''
    try {
      template_settings = JSON.parse(this.settingsTarget.value)
    } catch (e) {
      template_settings = defaultTemplateSettings();
    }

    let stripe_payment_intent = null
    try {
      stripe_payment_intent = JSON.parse(this.data.get('stripePaymentIntent'));
    } catch (e) {
      // stripe_payment_intent = defaultStripePaymentIntentJSON();
    }

    let stripe_customer = null
    try {
      stripe_customer = JSON.parse(this.data.get('stripeCustomer'));
    } catch (e) {
      // stripe_customer = defaultStripeCustomerJSON();
    }

    let stripe_invoice = null
    try {
      stripe_invoice = JSON.parse(this.data.get('stripeInvoice'));
    } catch (e) {
      // stripe_invoice = defaultStripeInvoiceJSON();
    }

    let stripe_charge = null
    try {
      stripe_charge = JSON.parse(this.data.get('stripeCharge'));
    } catch (e) {
      // stripe_invoice = defaultStripeInvoiceJSON();
    }

    let stripe_checkout_session = null
    try {
      stripe_checkout_session = JSON.parse(this.data.get('stripeCheckoutSession'));
    } catch (e) {
      // stripe_invoice = defaultStripeInvoiceJSON();
    }

    let payment_object = null
    try {
      payment_object = JSON.parse(this.data.get('paymentObject'));
    } catch(e) {
    }

    let themes = null;
    try {
      themes = JSON.parse(this.data.get('teamTemplateThemes'));
    } catch (e){

    }

    const company_settings = extractCompanySettings(this.data);

    [this.editor, this.editorSaveFunction] = initEditor({
      templateData: initialJson,
      team_id: this.data.get('teamId'),
      template_id: this.data.get('templateId'),
      template_event_type: this.data.get('templateEventType'),
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
      company_settings: company_settings,
      template_settings: template_settings,
      json_stripe_intent: stripe_payment_intent,
      json_stripe_customer: stripe_customer,
      json_stripe_invoice: stripe_invoice,
      json_stripe_charge: stripe_charge,
      json_stripe_checkout_session: stripe_checkout_session,
      payment_object: payment_object,
      themeData: themes,
    });
  }
}