const Selects = require('../../utils/selects.js');

/**
 * Selects & Tags
 */

'use strict';
function init() {

    Selects.init();

    const select2Actions = $('.select2-actions');
    const select2Blocktypes = $('.select2-blocktypes');
    const select2Theme = $('#selectTheme');

    // Select2 Actions
    if (select2Actions.length) {
        function renderItalic(option) {
            if (!option.id) {
                return option.text;
            }
            if (option.id === 'custom') {
                return "<i>" + option.text + "</i>";
            }
            if (option.id === 'variable1') {
                return option.text + '&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #696cff;" class="hide-on-select"><i class="bx bx-arrow-back"></i> most common</span>';
            }
            return option.text;
        }

        select2Actions.each(function () {
            var $this = $(this);
            $this.wrap('<div class="position-relative"></div>').select2({
                placeholder: 'Select value',
                allowClear: false,
                dropdownParent: $this.parent(),
                templateResult: renderItalic,
                templateSelection: renderItalic,
                escapeMarkup: function (es) {
                    return es;
                },
                minimumResultsForSearch: -1 //hide search field
            });
        });
    }

    // Select2 Block types
    if (select2Blocktypes.length) {
        select2Blocktypes.each(function () {
            var $this = $(this);
            $this.wrap('<div class="position-relative"></div>').select2({
                placeholder: 'Select type',
                allowClear: false,
                dropdownParent: $this.parent(),
                minimumResultsForSearch: -1 //hide search field
            });
        });
        select2Blocktypes.val(null).trigger('change'); //empty initial value

        select2Blocktypes.on('select2:select', function (e) {
            $('.blocks-container').hide();
            $('#blocks-'+e.params.data.id).show();
        });
    }

    if (select2Theme.length) {
        // custom template to render icons and color
        function renderColors(option) {
            if (!option.id || !$(option.element).data('color-1')) {
                return option.text;
            }
    
            let $icon1 = "<i class='bx bxs-square-rounded' style='color: " + $(option.element).data('color-1') + "'></i>";
            let $icon2 = "<i class='bx bxs-square-rounded' style='color: " + $(option.element).data('color-2') + "'></i>";
            let $icon3 = "<i class='bx bxs-square-rounded' style='color: " + $(option.element).data('color-3') + "'></i>";
            let $icon4 = "<i class='bx bxs-square-rounded me-2' style='color: " + $(option.element).data('color-4') + "'></i>";
        
            return $icon1 + $icon2 + $icon3 + $icon4 + option.text;
        }
        select2Theme.wrap('<div class="position-relative"></div>').select2({
            templateResult: renderColors,
            templateSelection: renderColors,
            escapeMarkup: function (es) {
                return es;
            },
            minimumResultsForSearch: -1 //hide search field
        });
    }

}

function populateBlocktypeSelect(element) {
    $(element).append($('<option>', { 
        value: 'receipt',
        text : 'Receipt',
        selected: true
    }));
    $(element).append($('<option>', { 
        value: 'packingslip',
        text : 'Packing slip'
    }));
}

function populateLocaleSelect(element) {
    Selects.populateLocaleSelect(element);
}

function populateTimezoneSelect(element) {
    Selects.populateTimezoneSelect(element);
}

module.exports = { init, populateBlocktypeSelect, populateLocaleSelect, populateTimezoneSelect };