import Handlebars from 'handlebars/dist/cjs/handlebars'
const CountryList = require('../../../utils/getAllCountries.js');
const Dinero = require('dinero.js');
const Lodash = require('lodash');

const getHandlebars = (templateVariables) => {

    const formatDate = (settings, input, showTime) => {
        const _isTimestamp = function (n) {
            const parsed = parseFloat(n);
            return !Number.isNaN(parsed) && Number.isFinite(parsed) && /^\d+\.?\d+$/.test(n);
        }

        let locale = settings.template.locale;
        let date_format = settings.template.date_format;
        let timestamp = input * 1000;

        if ( _isTimestamp(timestamp) ) {
            let { hour, minute, ...only_date_format} = date_format;
            return new Intl.DateTimeFormat(locale, showTime ? date_format: only_date_format).format(timestamp);
        } else {
            return "";
        }
    }
    
    Handlebars.registerHelper('showDate', function (input) {
        let date = formatDate(this.settings, input, false);
        return date;
    });

    Handlebars.registerHelper('showDateTime', function (input) {
        let date = formatDate(this.settings, input, true);
        return date;
    });

    Handlebars.registerHelper('showWithCurrency', function (inputAmount, inputCurrency) {

        //get precision from minor currency unit
        const _getPrecision = function (currency) {
            switch (currency) {
                case "djf": //Djibouti Franc, 0
                case "gnf": //Guinea Franc, 0
                case "jpy": //Japanese Yen, 0
                case "kmf": //Comoro Franc, 0
                case "krw": //South-Korean Won, 0
                case "pyg": //Paraguay Guarani, 0
                case "rwf": //Rwanda Franc, 0
                case "ugx": //Uganda Shilling, 0
                case "vnd": //Vietnamese New Dong, 0
                case "vuv": //Vanuatu Vatu, 0
                case "xaf": //CFA Franc BEAC, 0
                case "xof": //CFA Franc BCEAO, 0
                case "xpf": //CFP Franc, 0
                case "cve": //Cape Verdi Escudo, 0
                case "idr": //Indonesian Rupiah, 0
                    return 0;
                case "bhd": //Bahraini Dinar, 3
                case "iqd": //Iraqi Dinar, 3
                case "jod": //Jordanian Dinar, 3
                case "kwd": //Kuwaiti Dinar, 3
                case "lyd": //Libyan Dinar, 3
                case "omr": //Rial Omani, 3
                case "tnd": //Tunisian Dinar, 3
                    return 3;
                default:
                    return 2;
            }
        }

        //get format from precision and decimals
        const _getFormat = function (amount, precision, hide_decimals_when_whole_number) {
            let format = '$0,0';
            let is_whole_number = (amount % 1) == 0 ? true : false;

            if (is_whole_number && hide_decimals_when_whole_number) {
                return format;
            } else {
                switch (precision) {
                    case 0: return format; break;
                    case 1: return format + '.0'; break;
                    case 2: return format + '.00'; break;
                    case 3: return format + '.000'; break;
                    default: return format + '.00';
                }
            }
        }

        //get amount from context object by string and try to find closest currency value in context object
        const _getAmountAndCurrencyFromPath = function (amountPath, context) {
            //if path does not exist, return null
            let amount = Lodash.get(context, amountPath, null);
            if (isNaN(amount) || amount === null) {
                return { amount: null, currency: null };
            }

            //find amount variable name
            let variable = amountPath.split('.').pop();

            //find root object that contains variable
            let rootPath = null;
            let rootObj = context;
            let lastDot = amountPath.lastIndexOf(".");
            if (lastDot > 0) {
                rootPath = amountPath.slice(0, lastDot);
                rootObj = Lodash.get(context, rootPath);
            }

            //if currency does not exist in root object, try to find currency higher up in context object
            if (!rootObj['currency']) {
                //if parent level exists in context, try to find currency there
                if (rootPath === null) {
                    return { amount: rootObj[variable], currency: null };
                } else {
                    return { amount: rootObj[variable], currency: _findCurrencyInParent(rootPath, context) };
                }
            }

            //return amount and currency
            return { amount: rootObj[variable], currency: rootObj['currency'] };
        }

        const _findCurrencyInParent = function (rootPath, context) {
            let lastDot = rootPath.lastIndexOf(".");
            if (lastDot > 0) {
                let parentPath = rootPath.slice(0, lastDot);
                let parentObj = Lodash.get(context, parentPath);
                if (parentObj['currency']) {
                    return parentObj['currency'];
                }
            }
            return null;
        }

        let amount, currency;
        let locale = templateVariables.settings.template.locale;
        let hide_decimals_when_whole_number = templateVariables.settings.template.hide_decimals_when_whole_number;

        if (typeof inputCurrency === "string" && !isNaN(inputAmount)) {
            amount = Math.round(inputAmount);
            currency = inputCurrency;
        } else {
            if (typeof inputAmount === "string") {
                let helperContext = this;
                let amountObj = _getAmountAndCurrencyFromPath(inputAmount, helperContext);

                amount = amountObj.amount;
                currency = amountObj.currency;
            } else {
                return null;
            }
        }

        if (amount === null) {
            return null;
        }
        if (currency === null) {
            return new amount
        }

        let precision = _getPrecision(currency);
        let dinero = Dinero({ amount: amount, currency: currency, precision: precision }).setLocale(locale);
        let format = _getFormat(dinero.toUnit(), precision, hide_decimals_when_whole_number);

        return dinero.toFormat(format);
    });

    Handlebars.registerHelper('showCountryName', function (input) {
        let country = CountryList.getCountryNameFromCode(input);
        return country;
    });

    Handlebars.registerHelper('fullAddress', function (input) {
        let source, value;

        //check if helper called from i.e. #with, with address
        if (this?.address) {
            source = this.address;
        }
        
        //check if helper is called with argument, with address
        if (input?.address) {
            source = input.address;
        }

        if (!source) {
            return "";
        }

        value = (source.line1 ? Handlebars.helpers.linebreakAfter(source.line1) : "") +
                (source.line2 ? Handlebars.helpers.linebreakAfter(source.line2) : "") + 
                (source.postal_code ? Handlebars.escapeExpression(source.postal_code) + " " : "") + (source.city ? Handlebars.helpers.linebreakAfter(source.city) : "") +
                (source.state ? Handlebars.helpers.linebreakAfter(source.state) : "") + 
                (source.country ? Handlebars.helpers.showCountryName(source.country) : "");

        return new Handlebars.SafeString(value);
    })

    Handlebars.registerHelper('linebreakBefore', function (input) {
        if (input) {
            return new Handlebars.SafeString("<br>" + Handlebars.Utils.escapeExpression(input));
        }
        else {
            return "";
        }
    });

    Handlebars.registerHelper('linebreakAfter', function (input) {
        if (input) {
            return new Handlebars.SafeString(Handlebars.Utils.escapeExpression(input) + "<br>");
        }
        else {
            return "";
        }
    });

    Handlebars.registerHelper('firstWithValue', function (...args) {
        const _isEmpty = function (value) {
            return (value === null || value === undefined || value.length === 0 || typeof(value) === "undefined");
        }

        for (var i = 0; i < args.length; i++) {
            if (!_isEmpty(args[i])) {
                return args[i];
            }
        }
        return "";
    });

    Handlebars.registerHelper("math", function(lvalue, operator, rvalue, options) {
        lvalue = parseFloat(lvalue);
        rvalue = parseFloat(rvalue);

        if (isNaN(lvalue) || isNaN(rvalue)) {
            return null;
        }

        if (operator !== "+" && operator !== "-" && operator !== "*" && operator !== "/" && operator !== "%") {
            return null;
        }
            
        return {
            "+": lvalue + rvalue,
            "-": lvalue - rvalue,
            "*": lvalue * rvalue,
            "/": lvalue / rvalue,
            "%": lvalue % rvalue
        }[operator];
    });

    Handlebars.registerHelper('capitalize', function (str) {
        if (str && typeof str === 'string') {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    });

    Handlebars.registerHelper('capitalizeAll', function (str) {
        if (str && typeof str === 'string') {
            return str.replace(/\w\S*/g, function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            });
        }
    });

    Handlebars.registerHelper('lowercase', function (str) {
        if (str && typeof str === 'string') {
            return str.toLowerCase();
        }
    });


    //Return Handlebars, now with helpers registered
    return Handlebars;

}


module.exports = { getHandlebars };
