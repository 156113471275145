import Handlebars from 'handlebars/dist/cjs/handlebars'
const Selects = require('../template-editor/utils/selects.js');

import Pickr from '@simonwep/pickr';


const initThemeSettings = function(themesTarget, themeData) {
  initThemeSelect(themesTarget, themeData);
  initColorPickers(themesTarget, themeData);

  $('#themeNameField').on('change', function (e) {
    let name = e.currentTarget.value;
    if(!name || name == ''){
      name = 'Theme';
    }
    setThemeDataProp(themeData, themesTarget, 'name', name);
  });
}

const setThemeDataProp = function (themeData, themesTarget, prop, value) {
  themeData[$('#chosenThemeField').val()][prop] = value;
  console.log(themeData);
  themesTarget.value = JSON.stringify(themeData);
}

/**
 * Init
 */
const initSettings = function () {
  if(document.getElementById("selectLocale")){
    initSelects();
  }

  if($('#emailPreview').length > 0){
    initEmailPreview();
  }

  $('.discard-changes').on('click', function (e) {
    location.reload();
  });
};


/**
 * Theme settings
 */

const initThemeSelect = function (themesTarget, themeData) {
  $('#themeSelect1 .theme-name').text( themeData.theme1.name );
  $('#themeSelect1 .theme-color1').css('color', themeData.theme1.color1 );
  $('#themeSelect1 .theme-color2').css('color', themeData.theme1.color2 );
  $('#themeSelect1 .theme-color3').css('color', themeData.theme1.color3 );
  $('#themeSelect1 .theme-color4').css('color', themeData.theme1.color4 );

  $('#themeSelect2 .theme-name').text( themeData.theme2.name );
  $('#themeSelect2 .theme-color1').css('color', themeData.theme2.color1 );
  $('#themeSelect2 .theme-color2').css('color', themeData.theme2.color2 );
  $('#themeSelect2 .theme-color3').css('color', themeData.theme2.color3 );
  $('#themeSelect2 .theme-color4').css('color', themeData.theme2.color4 );

  $('#themeSelect3 .theme-name').text( themeData.theme3.name );
  $('#themeSelect3 .theme-color1').css('color', themeData.theme3.color1 );
  $('#themeSelect3 .theme-color2').css('color', themeData.theme3.color2 );
  $('#themeSelect3 .theme-color3').css('color', themeData.theme3.color3 );
  $('#themeSelect3 .theme-color4').css('color', themeData.theme3.color4 );
};

const initSelects = function () {
  Selects.init();
  Selects.populateCountrySelect( document.getElementById("selectAddressCountry") );
  Selects.populateLocaleSelect( document.getElementById("selectLocale") );
  Selects.populateTimezoneSelect( document.getElementById("selectTimezone") );
};

const getColorPickerOptions = function (element) {
  return {
    el: element,
    theme: 'monolith',
    components: {
      preview: true,
      hue: true,
      interaction: {
        input: true,
        save: true
      }
    },
    i18n: {
      'btn:save': 'Select',
      'aria:btn:save': 'select and close',
    }
  };
}

const getColorHex = (instance) => instance.getSelectedColor().toHEXA().toString();

const initColorPickers = function (themesTarget, themeData) {
  const pickr_1 = Pickr.create( getColorPickerOptions('#color-picker-1') );
  const pickr_2 = Pickr.create( getColorPickerOptions('#color-picker-2') );
  const pickr_3 = Pickr.create( getColorPickerOptions('#color-picker-3') );
  const pickr_4 = Pickr.create( getColorPickerOptions('#color-picker-4') );

  pickr_1.on('save', (color, instance) => {
    instance.hide();
    const colorHex = getColorHex(instance);
    $('#color-picker-preview-header').css('color', colorHex);
    setThemeDataProp(themeData, themesTarget, 'color1', colorHex);
  });
  pickr_2.on('save', (color, instance) => {
    instance.hide();
    const colorHex = getColorHex(instance);
    $('#color-picker-preview-table thead tr th').css('color', colorHex);
    setThemeDataProp(themeData, themesTarget, 'color2', colorHex);
  });
  pickr_3.on('save', (color, instance) => {
    instance.hide();
    const colorHex = getColorHex(instance);
    $('#color-picker-preview-table').css('border-color', colorHex);
    setThemeDataProp(themeData, themesTarget, 'color3', colorHex);
  });
  pickr_4.on('save', (color, instance) => {
    instance.hide();
    const colorHex = getColorHex(instance);
    $('#color-picker-preview-delimiter').css('color', colorHex);
    setThemeDataProp(themeData, themesTarget, 'color4', colorHex);
  });

  pickr_4.on('init', instance => {
    $('.theme-select').on('click', function (e) {
      $('.theme-select').removeClass('border-primary');
      $('#themesFlash').hide();
      switch (e.currentTarget.id) {
        case 'themeSelect1':
          $(e.currentTarget).addClass('border-primary');
          $('#chosenThemeField').val('theme1');
          pickr_1.setColor( themeData.theme1.color1 );
          pickr_2.setColor( themeData.theme1.color2 );
          pickr_3.setColor( themeData.theme1.color3 );
          pickr_4.setColor( themeData.theme1.color4 );
          $('#themeNameField').val( themeData.theme1.name );
          break;
        case 'themeSelect2':
          $('#chosenThemeField').val('theme2');
          $(e.currentTarget).addClass('border-primary');
          pickr_1.setColor( themeData.theme2.color1 );
          pickr_2.setColor( themeData.theme2.color2 );
          pickr_3.setColor( themeData.theme2.color3 );
          pickr_4.setColor( themeData.theme2.color4 );
          $('#themeNameField').val( themeData.theme2.name );
          break;
        case 'themeSelect3':
          $('#chosenThemeField').val('theme3');
          $(e.currentTarget).addClass('border-primary');
          pickr_1.setColor( themeData.theme3.color1 );
          pickr_2.setColor( themeData.theme3.color2 );
          pickr_3.setColor( themeData.theme3.color3 );
          pickr_4.setColor( themeData.theme3.color4 );
          $('#themeNameField').val( themeData.theme3.name );
          break;
      }
      $('#themeEdit, #themeEditLine').show();
    });
  });
}


/**
 * Email settings
 */

const getEmailFrame = function () {
  return $("#emailPreview")
}

const initEmailPreview = function () {

  let handlebarTemplateEmail;

  getEmailFrame().on("load", function() {
    $('#emailPreviewLoader').hide();
    $('#emailPreviewContainer').show();
    $('#switchVariables').removeAttr("disabled");

    handlebarTemplateEmail = compileHandlebarTemplateEmail();
    updateEmailBodyPreview(handlebarTemplateEmail);
  });

  $('#switchVariables').on('change', function (e) {
    updateEmailBodyPreview(handlebarTemplateEmail);
  });

  $('.preview-input').on('keyup change', function (e) {
    let showVariables = $('#switchVariables').is(':checked');
    if (!showVariables) {
      updateEmailBodyPreview(handlebarTemplateEmail);
    }
  });
}

const compileHandlebarTemplateEmail = function () {
  let content = getEmailFrame().contents().find('body').html();
  let template = Handlebars.compile( fixHandlebarsSyntax(content) );
  return template;
}

const resizePreviewFrame = function () {
  let emailFrameScrollHeight = getEmailFrame().contents().find('body').get(0).scrollHeight;
  getEmailFrame().height(emailFrameScrollHeight);
}

const updateEmailBodyPreview = function (handlebarTemplate) {
  let showVariables = $('#switchVariables').is(':checked');
  let previewFrame = getEmailFrame().contents().find('body');

  let subject = '{{ subject }}';
  let header = '{{ header }}';
  let paragraph = '{{ paragraph }}';
  let action = '{{ action }}';
  let action_link = 'action_link';
  let footer = '{{ footer }}';

  let formSubject = $('#emailSubject').val();
  let formHeader = $('#emailBodyHeader').val();
  let formParagraph = $('#emailBodyParagraph').val();
  let formAction = $('#emailBodyAction').val();
  let formAction_link = $('#emailBodyActionLink').val();
  let formFooter = $('#emailBodyFooter').val();

  if (!showVariables) {
    subject = formSubject ? formSubject : '';
    header = formHeader ? formHeader : '';
    paragraph = formParagraph ? formParagraph : '';
    action = formAction ? formAction : '';
    action_link = formAction_link ? 'https://'+formAction_link : '';
    footer = formFooter ? formFooter : '';
  }
  
  let handlebarResult = handlebarTemplate({
                                          subject: subject,
                                          header: header,
                                          paragraph: paragraph,
                                          action: action,
                                          footer: footer
                                        });
  previewFrame.html(handlebarResult);
  formatActionLink(previewFrame, action_link);

  resizePreviewFrame();
}

const fixHandlebarsSyntax = function (content) {
  content = content.replaceAll(/%{(.*?)}/g, function(m, content) {
    return '{{' + content + '}}';
  });
  return content;
}

const formatActionLink = function (previewFrame, action_link) {
  if (action_link == 'action_link' || action_link == '') {
    previewFrame.find('a[name="action_link"]').attr('href','https://empty');
    previewFrame.find('a[name="action_link"]').attr('onclick','return false;');
  } else {
    previewFrame.find('a[name="action_link"]').attr('href',action_link);
  }
}

module.exports = { initSettings, initThemeSettings }