export function extractCompanySettings(data) {
  return {
    "company": {
      "name": data.get('templateCompanyName'),
      "email": data.get('templateCompanyEmail'),
      "locale": data.get('templateCompanyLocale'),
      "timezone": data.get('templateCompanyTimezone'),
      'phone': data.get('templateCompanyPhone'),
      'website': data.get('templateCompanyWebsite'),
      "address": {
        "line1": data.get('templateAddressLine1'),
        "line2": data.get('templateAddressLine2'),
        "postal_code": data.get('templateAddressZip'),
        "city": data.get('templateAddressCity'),
        "state": data.get('templateAddressState'),
        "country": data.get('templateAddressCountry'),
      }
    },
  };
}

export function defaultTemplateJSON() {
  return {
    "blocks": [
      {
        "type": "paragraph",
        "data": {
          "text": ""
        }
      }
    ]
  }
}

export function defaultTemplateSettings() {
  return {
    "actions": '',
    "locale": null,
    "timezone": null,
    "date_format": {
      "year": 'numeric',
      "month": 'numeric',
      "day": 'numeric',
      "hour": 'numeric',
      "minute": 'numeric',
      "timeZone": 'America/New_York',
    },
    "hide_decimals_when_whole_number": true,
  };
}

/**
 * Stripe mock test data
 */
export function defaultStripePaymentIntentJSON() {
  const json_stripe_intent = {
    "id": "pi_RANDOMID",
    "object": "payment_intent",
    "amount": 1000,
    "amount_capturable": 0,
    "amount_details": {
      "tip": {}
    },
    "amount_received": 0,
    "application": null,
    "application_fee_amount": null,
    "automatic_payment_methods": null,
    "canceled_at": null,
    "cancellation_reason": null,
    "capture_method": "automatic",
    "client_secret": "A_LONG_STRING_SECRET",
    "confirmation_method": "automatic",
    "created": 1627976550,
    "currency": "usd",
    "customer": null,
    "description": "Created by stripe.com/docs demo",
    "invoice": null,
    "last_payment_error": null,
    "latest_charge": null,
    "livemode": false,
    "metadata": {},
    "next_action": null,
    "on_behalf_of": null,
    "payment_method": null,
    "payment_method_options": {
      "card": {
        "installments": null,
        "mandate_options": null,
        "network": null,
        "request_three_d_secure": "automatic"
      }
    },
    "payment_method_types": [
      "card"
    ],
    "processing": null,
    "receipt_email": null,
    "review": null,
    "setup_future_usage": null,
    "shipping": null,
    "statement_descriptor": null,
    "statement_descriptor_suffix": null,
    "status": "requires_payment_method",
    "transfer_data": null,
    "transfer_group": null
  };
  return json_stripe_intent;
}

export function defaultStripeCustomerJSON() {
  const json_stripe_customer = {
    "id": "cus_RANDOMID",
    "object": "customer",
    "address": {
      "city": "ADDRESS CITY",
      "country": "ADDRESS COUNTRY TWO LETTERS",
      "line1": "ADDRESSLINE 1",
      "line2": "ADDRESSLINE 1",
      "postal_code": "ADDRESS ZIP",
      "state": "ADDRESS STATE"
    },
    "balance": 0,
    "created": 1668973323,
    "currency": null,
    "default_source": "src_RANDOMID",
    "delinquent": false,
    "description": null,
    "discount": null,
    "email": "anemail@example.com",
    "invoice_prefix": "THEINVOICEPREFIXSETTING",
    "invoice_settings": {
      "custom_fields": null,
      "default_payment_method": null,
      "footer": null,
      "rendering_options": null
    },
    "livemode": false,
    "metadata": {},
    "name": "A Person Name from Customer",
    "phone": null,
    "preferred_locales": [],
    "shipping": null,
    "tax_exempt": "none",
    "test_clock": null
  };
  return json_stripe_customer;
}

export function defaultStripeInvoiceJSON() {
  const json_stripe_invoice = {
    "id": "in_RANDOMID",
    "object": "invoice",
    "account_country": "SE",
    "account_name": "Paperplain",
    "account_tax_ids": null,
    "amount_due": 100000,
    "amount_paid": 0,
    "amount_remaining": 100000,
    "application": null,
    "application_fee_amount": null,
    "attempt_count": 0,
    "attempted": false,
    "auto_advance": true,
    "automatic_tax": {
      "enabled": false,
      "status": null
    },
    "billing_reason": "manual",
    "charge": null,
    "collection_method": "charge_automatically",
    "created": 1673731786,
    "currency": "sek",
    "custom_fields": null,
    "customer": "cus_RANDOMID",
    "address": {
      "city": "ADDRESS CITY",
      "country": "ADDRESS COUNTRY TWO LETTERS",
      "line1": "ADDRESSLINE 1",
      "line2": "ADDRESSLINE 1",
      "postal_code": "ADDRESS ZIP",
      "state": "ADDRESS STATE"
    },
    "customer_email": "CUSTOMEMAIL@example.com",
    "customer_name": "A CUSTOMER NAME",
    "customer_phone": null,
    "customer_shipping": null,
    "customer_tax_exempt": "none",
    "customer_tax_ids": [],
    "default_payment_method": null,
    "default_source": null,
    "default_tax_rates": [],
    "description": null,
    "discount": null,
    "discounts": [],
    "due_date": null,
    "ending_balance": null,
    "footer": null,
    "from_invoice": null,
    "hosted_invoice_url": null,
    "invoice_pdf": null,
    "last_finalization_error": null,
    "latest_revision": null,
    "lines": {
      "object": "list",
      "data": [
        {
          "id": "il_RANDOMID",
          "object": "line_item",
          "amount": 100000,
          "amount_excluding_tax": 100000,
          "currency": "sek",
          "description": "My First Invoice Item (created for API docs)",
          "discount_amounts": [],
          "discountable": true,
          "discounts": [],
          "invoice_item": "ii_RANDOMID",
          "livemode": false,
          "metadata": {},
          "period": {
            "end": 1673731786,
            "start": 1673731786
          },
          "price": {
            "id": "price_RANDOMID",
            "object": "price",
            "active": true,
            "billing_scheme": "per_unit",
            "created": 1667338737,
            "currency": "sek",
            "custom_unit_amount": null,
            "livemode": false,
            "lookup_key": null,
            "metadata": {},
            "nickname": null,
            "product": "prod_RANDOMID",
            "recurring": null,
            "tax_behavior": "unspecified",
            "tiers_mode": null,
            "transform_quantity": null,
            "type": "one_time",
            "unit_amount": 100000,
            "unit_amount_decimal": "100000"
          },
          "proration": false,
          "proration_details": {
            "credited_items": null
          },
          "quantity": 1,
          "subscription": null,
          "tax_amounts": [],
          "tax_rates": [],
          "type": "invoiceitem",
          "unit_amount_excluding_tax": "100000"
        },
        {
          "id": "il_RANDOMID",
          "object": "line_item",
          "amount": 100000,
          "amount_excluding_tax": 100000,
          "currency": "sek",
          "description": "My First Invoice Item (created for API docs)",
          "discount_amounts": [],
          "discountable": true,
          "discounts": [],
          "invoice_item": "ii_RANDOMID",
          "livemode": false,
          "metadata": {},
          "period": {
            "end": 1673731786,
            "start": 1673731786
          },
          "price": {
            "id": "price_RANDOMID",
            "object": "price",
            "active": true,
            "billing_scheme": "per_unit",
            "created": 1667338737,
            "currency": "sek",
            "custom_unit_amount": null,
            "livemode": false,
            "lookup_key": null,
            "metadata": {},
            "nickname": null,
            "product": "prod_RANDOMID",
            "recurring": null,
            "tax_behavior": "unspecified",
            "tiers_mode": null,
            "transform_quantity": null,
            "type": "one_time",
            "unit_amount": 100000,
            "unit_amount_decimal": "100000"
          },
          "proration": false,
          "proration_details": {
            "credited_items": null
          },
          "quantity": 1,
          "subscription": null,
          "tax_amounts": [],
          "tax_rates": [],
          "type": "invoiceitem",
          "unit_amount_excluding_tax": "100000"
        }
      ],
      "has_more": false,
      "url": "/v1/invoices/in_RANDOMID/lines"
    },
    "livemode": false,
    "metadata": {},
    "next_payment_attempt": 1673735386,
    "number": null,
    "on_behalf_of": null,
    "paid": false,
    "paid_out_of_band": false,
    "payment_intent": null,
    "payment_settings": {
      "default_mandate": null,
      "payment_method_options": null,
      "payment_method_types": null
    },
    "period_end": 1673731786,
    "period_start": 1673731786,
    "post_payment_credit_notes_amount": 0,
    "pre_payment_credit_notes_amount": 0,
    "quote": null,
    "receipt_number": null,
    "rendering_options": null,
    "starting_balance": 0,
    "statement_descriptor": null,
    "status": "draft",
    "status_transitions": {
      "finalized_at": null,
      "marked_uncollectible_at": null,
      "paid_at": null,
      "voided_at": null
    },
    "subscription": null,
    "subtotal": 100000,
    "subtotal_excluding_tax": 100000,
    "tax": null,
    "test_clock": null,
    "total": 100000,
    "total_discount_amounts": [],
    "total_excluding_tax": 100000,
    "total_tax_amounts": [],
    "transfer_data": null,
    "webhooks_delivered_at": null
  };
  return json_stripe_invoice;
}