const Lodash = require('lodash');

const CustomStyles = require('./modules/custom-styles');
const TemplateEditor = require('./modules/template-editor');

export function initEditor({ templateData, stripeObject, company_settings, template_settings, paymentObject, themesData}) {

  //Create object holding data for template variables
  const templateVariables = {};
  templateVariables["stripe"] = stripeObject;
  templateVariables["payment"] = paymentObject;
  templateVariables["settings"] = company_settings;
  templateVariables.settings["template"] = template_settings;

  CustomStyles.init(templateVariables, themesData);

  let editor;

  const onEditorReady = () => {
    initTopMenu(editor);
    initToolbarMenu();
  }

  editor = TemplateEditor.init("use", templateData, templateVariables, null, onEditorReady);
}

/**
 * Buttons and menus
 */

const initTopMenu = function (editor) {
  $('#btn-edit').prop("disabled", false);
  $('#btn-edit').on('click', function (e) {
    editClick(editor);
  });
};

const initToolbarMenu = function () {
  $('#menu-item-print').removeClass('disabled');
  $('#menu-item-download-pdf').removeClass('disabled');

  $('#menu-item-print a').on('click', function (e) {
    if (!$('#menu-item-print').hasClass('disabled')) {
      window.print();
    }
  });

  $('#menu-item-download-pdf a').on('click', function (e) {
    if (!$('#menu-item-download-pdf').hasClass('disabled')) {
      const controllers = $('#template-use-controller-wrapper');
      if(controllers.length > 0){
       controllers[0]['template-use'].download();
      }
    }
  });

};

const editClick = function (editor) {
  if (editor.readOnly.isEnabled) {
    $('#menu-item-print').addClass('disabled');
    $('#menu-item-download-pdf').addClass('disabled');
    $('#menu-hamburger-icon').hide();
    $('#btn-edit').html('Done');
  } else {
    $('#menu-item-print').removeClass('disabled');
    $('#menu-item-download-pdf').removeClass('disabled');
    $('#menu-hamburger-icon').show();
    $('#btn-edit').html('Edit content');
  }
  editor.readOnly.toggle();
};