/**
 * Build styles
 */

class MarginBlockTune {

    /**
     * Default border
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_MARGIN() {
        return 'default';
    }

    static get isTune() {
        return true;
    }

    getMargin(){
        if(!!this.settings?.blocks && this.settings.blocks.hasOwnProperty(this.block.name)){
            return this.settings.blocks[this.block.name]
        }
        if(!!this.settings?.default){
            return this.settings.default
        }
        return MarginBlockTune.DEFAULT_MARGIN
    }
    /**
     *
     * @param api
     * @param data 既に設定されているデータ
     * @param settings tuneに設定項目
     * @param block tuneに設定されてるblock
     */
    constructor({ api, data, config, block}) {
        this.api = api;
        this.block = block;
        /**
         config:{
            default: "show",
            blocks: {
              header: 'show',
              list: 'show'
            }
          },
         */
        this.settings = config;
        this.data = data || { margin: this.getMargin() }
        this.marginSettings = [
            {
                name: 'default',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16"><path style="stroke: none;" fill-rule="evenodd" d="M8 9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/></svg>',
                tooltip: 'Default margin'
            },
            {
                name: 'more',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16"><path style="stroke: none;" fill-rule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/></svg>',
                tooltip: 'More margin'
            }
        ];
        this._CSS = {
            margin: {
                default: 'ce-tune-margin--default',
                more: 'ce-tune-margin--more',
            }
        }
    }

    /**
     * @param blockContent
     */
    wrap(blockContent) {
        this.wrapper = document.createElement('div');
        this.wrapper.classList.toggle(this._CSS.margin[this.data.margin])
        this.wrapper.append(blockContent)
        return this.wrapper
    }

    /**
     * rendering block tune
     * @returns {*}
     */
    render() {
        const wrapper = document.createElement('div');
        this.marginSettings.map(domargin => {
            const button = document.createElement('button');
            button.classList.add(this.api.styles.settingsButton);
            button.innerHTML = domargin.icon;
            button.type = 'button';

            button.classList.toggle(this.api.styles.settingsButtonActive, domargin.name === this.data.margin);
            this.api.tooltip.onHover(button, domargin.tooltip, {
                        placement: "top",
                        hidingDelay: 10
                    });
            wrapper.appendChild(button);
            return button
        }).forEach((element, index, elements) => {
            element.addEventListener('click', () => {
                this.data = {
                    margin: this.marginSettings[index].name
                }
                elements.forEach((el, i) => {
                    const {name} = this.marginSettings[i];
                    el.classList.toggle(this.api.styles.settingsButtonActive, name === this.data.margin);
                    //toggle border style class for block
                    this.wrapper.classList.toggle(this._CSS.margin[name], name === this.data.margin)
                });
            });
        });
        return wrapper;
    }
    /**
     * save
     * @returns {*}
     */
    save() {
        return this.data;
    }
}

module.exports = MarginBlockTune;
