import { Controller } from "@hotwired/stimulus"
import { initEditor } from "../template-editor/template-editor-use";
import {defaultTemplateJSON, defaultTemplateSettings, extractCompanySettings} from "./template-utils";
import theme from "tailwindcss/defaultTheme";

// Connects to data-controller="template-editor"
export default class extends Controller {
    connect() {
        this.element[this.identifier] = this;

        let initialJson = ''
        try {
            initialJson = JSON.parse(this.data.get('body'));
        } catch (e) {
            // If this doeesn't work, let's use a basic starter
            initialJson = defaultTemplateJSON();
        }

        let template_settings = ''
        try {
            template_settings = JSON.parse(this.data.get('settings'));
        } catch (e) {
            template_settings = defaultTemplateSettings();
        }

        const company_settings = extractCompanySettings(this.data);

        console.log(template_settings);
        console.log(company_settings);

        let stripeObject = null;
        try {
            stripeObject = JSON.parse(this.data.get('stripeObject'));
        } catch (e){
            // Empty catch, we want stripObject to be null
        }

        let paymentObject = null;
        try {
            paymentObject = JSON.parse(this.data.get('paymentObject'));
        } catch (e){
            // Empty catch, we want stripObject to be null
        }

        let themesData = null;
        try {
            themesData = JSON.parse(this.data.get('teamTemplateThemes'));
        } catch (e){
            // Empty catch, we want stripObject to be null
        }

        let initObject = {};
        switch (stripeObject?.object){
            case 'payment_intent':
                initObject = {intent: stripeObject};
                break;
            case 'customer':
                initObject = {customer: stripeObject};
                break;
            case 'invoice':
                initObject = {invoice: stripeObject};
                break;
            case 'charge':
                initObject = {charge: stripeObject};
                break;
            case 'checkout.session':
                initObject = {checkout_session: stripeObject};
                break;
        }

        initEditor({
            templateData: initialJson,
            stripeObject: initObject,
            company_settings: company_settings,
            template_settings: template_settings,
            paymentObject: paymentObject,
            themesData: themesData,
        });
    }

    async download() {
        const downloadText = $('#menu-item-download-pdf a div');
        const oldText = downloadText.text();
        downloadText.text('');
        const loader = document.createElement('div');
        loader.setAttribute('class', 'loader');
        const loaderWrapper = document.createElement('div');
        loaderWrapper.setAttribute('class', 'loader-wrapper');
        loaderWrapper.appendChild(loader);
        downloadText.append(loaderWrapper);
        const that = this;
        let filename = 'unnamed';
        fetch(this.data.get('pdfUrl'))
          .then(res => {
              const header = res.headers.get('Content-Disposition');
              const parts = header.split(';');
              filename = parts[1].split('=')[1].replace('"', "").replace('"',"");
              console.log(filename);
              return res.blob();
          })
          .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              // the filename you want
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              $('#menu-item-download-pdf a div').html('')
              $('#menu-item-download-pdf a div').text(oldText);
          })
          .catch(() => alert('oh no!'));
    }
}