import { Controller } from "@hotwired/stimulus"
import { initThemeSettings } from "../settings/settings";

// Connects to data-controller="themes"
export default class extends Controller {
  static targets = ['themes']
  connect() {
    if($('#color-picker-1').length > 0) this.initThemes();
  }

  initThemes() {
    const themes = JSON.parse(this.themesTarget.value);
    initThemeSettings(this.themesTarget, themes);
  }
}