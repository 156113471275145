/**
 * JSONViewer
 */
JSONViewer = (function() {
  var JSONViewer = function() {
    this._dom = {};
    this._dom.container = document.createElement("pre");
    this._dom.container.classList.add("json-viewer");
    this._jsonData = {};
    this._variablePrefix = "";
    this._showOnlyHighlighted;
  };

  /**
   * Visualise JSON object.
   *
   * @param {Object|Array} json Input value
   * @param {Number} [maxLvl] Process only to max level, where 0..n, -1 unlimited
   * @param {Number} [colAt] Collapse at level, where 0..n, -1 unlimited
   */
  JSONViewer.prototype.showJSON = function(json, maxLvl, colAt, showOnlyHighlighted = null) {

    this._showOnlyHighlighted = showOnlyHighlighted;

    maxLvl = typeof maxLvl === "number" ? maxLvl : -1; // max level
    colAt = typeof colAt === "number" ? colAt : -1; // collapse at

    var jsonData = this._processInput(json);
    this._jsonData = jsonData;

    var walkEl = this._walk(jsonData, maxLvl, colAt, 0, "");

    this._dom.container.innerHTML = "";
    this._dom.container.appendChild(walkEl);

    //dispatch change event
    let event = new Event("jsonviewer_change", {bubbles: true});
    this._dom.container.dispatchEvent(event);
  };


  /**
   * Set naming prefix
   *
   */
  JSONViewer.prototype.setVariablePrefix = function(prefix) {
    this._variablePrefix = prefix;
  };

  /**
   * Get container with pre object - this container is used for visualise JSON data.
   *
   * @return {Element}
   */
  JSONViewer.prototype.getContainer = function() {
    return this._dom.container;
  };

  /**
   * Process input JSON - throws exception for unrecognized input.
   *
   * @param {Object|Array} json Input value
   * @return {Object|Array}
   */


  JSONViewer.prototype._processInput = function(json) {
    if (json && typeof json === "object") {
      return json;
    }
    else {
      throw "Input value is not object or array!";
    }
  };

  /**
   * Recursive walk for input value.
   *
   * @param {Object|Array} value Input value
   * @param {Number} maxLvl Process only to max level, where 0..n, -1 unlimited
   * @param {Number} colAt Collapse at level, where 0..n, -1 unlimited
   * @param {Number} lvl Current level
   */
  JSONViewer.prototype._walk = function(value, maxLvl, colAt, lvl, path) {
    var frag = document.createDocumentFragment();
    var isMaxLvl = maxLvl >= 0 && lvl >= maxLvl;
    var isCollapse = colAt >= 0 && lvl >= colAt;

    switch (typeof value) {
      case "object":
        if (value) {
          var isArray = Array.isArray(value);
          var items = isArray ? value : Object.keys(value);

          if (lvl === 0) {
            var rootChar = document.createElement("span");
            rootChar.innerText = isArray ? "[" : "{";
            frag.appendChild(rootChar);
          }

          if (items.length && !isMaxLvl) {
            var len = items.length - 1;
            var ulList = document.createElement("ul");
            ulList.setAttribute("data-level", lvl);
            ulList.classList.add("type-" + (isArray ? "array" : "object"));

            items.forEach(function(key, ind) {

              //update path
              var newPath;
              if (!isArray) {
                newPath = (path == "" ? key : path + "." + key);
              } else {
                newPath = path + "." + ind;
              }
              //console.log(newPath);

              var item = isArray ? key : value[key];
              var li = document.createElement("li");

              var variableLink = this._createVariableLink(key, lvl);

              //make key possible for using includes
              var keyString = "";
              if (typeof key === "string") {
                keyString = key;
              }

              if ((keyString.includes("__HIGHLIGHT") || lvl > 0) || (!this._showOnlyHighlighted || this._showOnlyHighlighted == null)) {

                if (typeof item === "object") {
                  var isEmpty = false;

                  // null && date
                  if (!item || item instanceof Date) {

                    if (!isArray) {
                      li.appendChild(variableLink);
                      li.appendChild(document.createTextNode(": "));
                    }

                    li.appendChild(this._createSimple(item ? item : null));
                  }
                  // array & object
                  else {
                    var itemIsArray = Array.isArray(item);
                    var itemLen = itemIsArray ? item.length : Object.keys(item).length;

                    // empty
                    if (!itemLen) {
                      li.appendChild(variableLink);
                      li.appendChild(document.createTextNode(": " + (itemIsArray ? "[]" : "{}")));
                    }
                    else {
                      // 1+ items
                      var itemTitle = (typeof key === "string" ? key : "");
                      var itemsCount = this._createItemsCount(itemLen);
                      var itemLink = this._createLink();
                      //var itemLinkTitle = this._createLink(itemTitle);
                      var itemsCountLink = this._createLinkElement(itemsCount);

                      // maxLvl - only text, no link
                      if (maxLvl >= 0 && lvl + 1 >= maxLvl) {
                        li.appendChild(variableLink);
                      }
                      else {
                        li.appendChild(itemLink);
                        li.appendChild(variableLink);
                        li.appendChild(document.createTextNode(": " + (itemIsArray ? "[" : "{")));
                        li.appendChild(itemsCountLink);
                      }

                      li.appendChild(this._walk(item, maxLvl, colAt, lvl + 1, newPath));
                      li.appendChild(document.createTextNode(itemIsArray ? "]" : "}"));

                      var list = li.querySelector("ul");
                      var itemLinkCb = function(container) {
                        itemLink.classList.toggle("collapsed");
                        itemsCount.classList.toggle("hide");
                        list.classList.toggle("hide");

                        //dispatch change event
                        if (container) {
                          let event = new Event("jsonviewer_change", {bubbles: true});
                          container.dispatchEvent(event);
                        }
                      };

                      // hide/show
                      itemLink.addEventListener("click", () => itemLinkCb(this._dom.container));
                      itemsCountLink.addEventListener("click", () => itemLinkCb(this._dom.container));

                      // collapse lower level
                      if (colAt >= 0 && lvl + 1 >= colAt) {
                        itemLinkCb();
                      }
                    }
                  }
                }
                // simple values
                else {
                  // object keys with key:
                  if (!isArray) {
                    li.appendChild(variableLink);
                    li.appendChild(document.createTextNode(": "));
                  }

                  // recursive
                  li.appendChild(this._walk(item, maxLvl, colAt, lvl + 1, newPath));
                }

                // add comma to the end
                if (ind < len) {
                  li.appendChild(document.createTextNode(","));
                }

                var variableLinkCb = function(e, jsonData, variablePrefix, path, container) {
                  //dispatch event
                  if (container) {
                    let eventDetail = {copyContent: variablePrefix + path.replace("__HIGHLIGHT","")};
                    let event = new CustomEvent("jsonviewer_variable_click", {detail: eventDetail, bubbles: true});
                    e.target.dispatchEvent(event);
                  }
                };

                variableLink.addEventListener("click", (e) => variableLinkCb(e, this._jsonData, this._variablePrefix, newPath, this._dom.container));

                ulList.appendChild(li);
              }
            }, this);

            frag.appendChild(ulList);
          }
          else if (items.length && isMaxLvl) {
            var itemsCount = this._createItemsCount(items.length);
            itemsCount.classList.remove("hide");

            frag.appendChild(itemsCount);
          }

          if (lvl === 0) {
            // empty root
            if (!items.length) {
              var itemsCount = this._createItemsCount(0);
              itemsCount.classList.remove("hide");

              frag.appendChild(itemsCount);
            }

            // root cover
            frag.appendChild(document.createTextNode(isArray ? "]" : "}"));

            // collapse
            if (isCollapse) {
              frag.querySelector("ul").classList.add("hide");
            }
          }
          break;
        }

      default:
        // simple values
        frag.appendChild(this._createSimple(value));
        break;
    }

    return frag;
  };

  /**
   * Create simple value (no object|array).
   *
   * @param  {Number|String|null|undefined|Date} value Input value
   * @return {Element}
   */
  JSONViewer.prototype._createSimple = function(value) {
    var spanEl = document.createElement("span");
    var type = typeof value;
    var txt = value;

    if (type === "string") {
      txt = '"' + value + '"';
    }
    else if (value === null) {
      type = "null";
      txt = "null";
    }
    else if (value === undefined) {
      txt = "undefined";
    }
    else if (value instanceof Date) {
      type = "date";
      txt = value.toString();
    }

    spanEl.classList.add("type-" + type);
    spanEl.innerText = txt;

    return spanEl;
  };

  /**
   * Create items count element.
   *
   * @param  {Number} count Items count
   * @return {Element}
   */
  JSONViewer.prototype._createItemsCount = function(count) {
    var itemsCount = document.createElement("span");
    itemsCount.classList.add("items-ph");
    itemsCount.classList.add("hide");
    itemsCount.innerText = this._getItemsTitle(count);

    return itemsCount;
  };

  /**
   * Create clickable link.
   *
   * @param  {String} title Link title
   * @return {Element}
   */
  JSONViewer.prototype._createLink = function(title) {
    var linkEl = document.createElement("a");
    linkEl.classList.add("list-link");
    linkEl.classList.add("arrow");
    linkEl.href = "javascript:void(0)";
    linkEl.innerText = title || "";

    return linkEl;
  };

  JSONViewer.prototype._createLinkElement = function(element) {
    var linkEl = document.createElement("span");
    linkEl.classList.add("list-link");
    linkEl.appendChild(element);

    return linkEl;
  };

  JSONViewer.prototype._createVariableLink = function(key, lvl) {
    var linkEl, newkey;

    linkEl = document.createElement("span");

    if (typeof key === "string") {
      linkEl = document.createElement("span");
      newKey = key;

      if ((key.includes("__HIGHLIGHT") && lvl == 0) || this._showOnlyHighlighted == null) {
        linkEl.classList.add("highlighted");
        newKey = key.replace("__HIGHLIGHT","");
      }

      linkEl.id = newKey;
      linkEl.classList.add("list-variable-link");
      linkEl.innerText = newKey || "";

    }

    return linkEl;

  };

  /**
   * Get correct item|s title for count.
   *
   * @param  {Number} count Items count
   * @return {String}
   */
  JSONViewer.prototype._getItemsTitle = function(count) {
    var itemsTxt = count > 1 || count === 0 ? "items" : "item";

    return (count + " " + itemsTxt);
  };

  return JSONViewer;
})();


module.exports = JSONViewer;