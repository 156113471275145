const init = (templateVariables, themesData) => {
    setCustomStyles(
      templateVariables,
      themesData
    );
}

const setCustomStyles = (templateVariables, themesData) => {

    if(!templateVariables.settings.template.theme) return;

    let color1, color2, color3, color4;
    if(templateVariables.settings.template.theme === 'default' || templateVariables.settings.template.theme === '') {
        color1 = defaultStyle.color1;
        color2 = defaultStyle.color2;
        color3 = defaultStyle.color3;
        color4 = defaultStyle.color4;
    } else {
        color1 = themesData[templateVariables.settings.template.theme].color1;
        color2 = themesData[templateVariables.settings.template.theme].color2;
        color3 = themesData[templateVariables.settings.template.theme].color3;
        color4 = themesData[templateVariables.settings.template.theme].color4;
    }



    let styleHeading = "#editorjs h1, #editorjs h2, #editorjs h3, #editorjs h4, #editorjs h5, #editorjs h6 {\n  color: " + color1 + " !important;\n}\n";
    let styleTableHeading = "#editorjs .tc-table--heading .tc-row:first-child {\n  color: " + color2 + " !important;\n}\n";
    let styleTableBorder = "#editorjs .tc-table, #editorjs .tc-row, #editorjs .tc-cell {\n  border-color: " + color3 + " !important;\n}\n";
    let styleDelimiter = "#editorjs .ce-delimiter {\n  color: " + color4 + " !important;\n  border-color: " + color4 + " !important;\n}\n";

    addStyle(styleHeading + styleTableHeading + styleTableBorder + styleDelimiter, "custom-style-colors");
}

const addStyle = (styles, styleId) => {
    let css_container = document.getElementById(styleId);
    if (css_container) {
        /* Update style element */
        css_container.innerText = styles;
    } else {
        /* Create style element */
        const css = document.createElement('style');
        css.type = 'text/css';
        css.id = styleId;
        css.appendChild(document.createTextNode(styles));
        document.getElementsByTagName("head")[0].appendChild(css);
    }
}

const defaultStyle = {
    name: 'default',
    color1: '#566a7f',
    color2: '#697A8D',
    color3: '#e8e8eb',
    color4: '#697A8D'
}

module.exports = { init, setCustomStyles, defaultStyle };
